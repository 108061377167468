// import React, { useState } from "react";
// import { motion } from "framer-motion";
// import { useGlobalContext } from "../../context";
// import { Navbar } from "../../components";
// import logo from "./../../assets/images/icons/logo-sm.png";
// import { MdArrowUpward } from "react-icons/md";
// import axios from "axios";

// export default function Chat() {
//     const { fade } = useGlobalContext().animations;
//     const chatData = [
//         {
//             id: 1,
//             logo: "R",
//             Title: "You",
//             text: "lorem100 ad fas fasfsd fafhas asdjkh jkfjs hfjhfjkas jkfh asjfhk hfjkasdh fashf hdfhkfhfh ashsfj",
//         },

//         {
//             id: 2,
//             logo: "R",
//             Title: "You",
//             text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
//         },
//     ];
//     const [prompt, setPrompt] = useState("");
//     const [history, setHistory] = useState(chatData);

//     const handleSubmit = () => {
//         axios
//             .post(
//                 "https://api.openai.com/v1/chat/completions",
//                 {
//                     prompt: prompt,
//                     max_tokens: 150,

//                     model: "gpt-3.5-turbo",
//                 },
//                 {
//                     headers: {
//                         Authorization: `Bearer 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`,
//                         "Content-Type": "application/json",
//                     },
//                 }
//             )
//             .then((res) => {
//                 console.log(res.data.choices[0].text);
//             })
//             .catch((error) => {
//                 console.error("Error fetching GPT response:", error);
//             });
//     };

//     return (
//         <motion.main {...fade} className="main ">
//             <Navbar title="All Marketing Strategist" />
//             <div className="parent">
//                 <div className="Chat">
//                     <div className="InnerChat ChatScrollbar">
//                         {history?.map((chat, index) => (
//                             <div key={index} className="chatMainDiv">
//                                 <div className="chatLogo">
//                                     <img src={logo} alt="" />
//                                 </div>
//                                 <div>
//                                     <div className="chatTitile">{chat.Title}</div>
//                                     <div className="chatText">{chat.text}</div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                     <div className="InputSection">
//                         <div className="InputMain">
//                             <input
//                                 type="text"
//                                 className="input"
//                                 value={prompt}
//                                 onChange={(e) => setPrompt(e.target.value)}
//                                 placeholder="Message ChatGPT..."
//                             />
//                             <button className="Submit" onClick={handleSubmit}>
//                                 <MdArrowUpward size={20} color="#fff" />
//                             </button>
//                         </div>
//                         <div className="InformationText">
//                             ChatGPT can make mistakes. Consider checking important information.import axios
//                             from 'axios';
//                         </div>
//                         import axios from 'axios';
//                     </div>
//                 </div>
//             </div>
//         </motion.main>
//     );
// }

import React, { useEffect, useState } from "react";
import {
    Navbar,
    Button,
    Blockchain,
    CampaignsTable,
    ActivityTable,
    Skeleton,
    Select,
    Drawer,
    Tabs,
    BasicChat,
} from "../../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";
import { v4 as uuidv4 } from "uuid";
import useFetch from "../../../hooks/useFetch";
import { auth } from "../../../config/firebase.js";

// import {db} from '../../config/firebase'
// import { getDocs,collection } from "firebase/firestore";

function BasicChatSection() {
    
    const { getChatGPTMessage } = useFetch();
    const { errorAlert,requestedData } = useGlobalContext();

    const processMessageToChatGPT = async (newMessages) => {
        try {
            const apiMessages = newMessages.map((item) => {
                const role = item.sender.toLowerCase() === "chatbot" ? "assistant" : "user";
                return { role, content: item.message };
            });

            const data = await getChatGPTMessage(undefined, apiMessages);
            return data.choices[0].message.content;
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=chatGPTMessage= request error");
        }
    };

    return (
        <div className="chat-section">
            {/* <div className="container"> */}
                {/* <h2 className="display-2">basic chat</h2> */}
                <BasicChat callback={processMessageToChatGPT}  />
            {/* </div> */}
        </div>
    );
}

export default function Chat() {
    useEffect(() => {
        document.title = "AI Marketing Strategist";

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content = "Engage in intelligent conversations with our AI chat. Get instant, personalized responses and assistance in real-time."
    }, []);

    const { fade } = useGlobalContext().animations;
    const {} = useGlobalContext();

    return (
        <motion.main {...fade} className="main chat-page">
            <Navbar title="AI Marketing Strategist" />

            <BasicChatSection />
        </motion.main>
    );
}
