import React, { useState, useEffect } from "react";
import { motion, warning } from "framer-motion";
import { useGlobalContext } from "../../../context";
import {
    Navbar,
    Button,
    Select,
    MultiSelect,
    Geochart,
    TrafficSourcesTable,
    SocialMetrics,
    Field,
    Testimonials,
    Tabs,
    Radio,
    Switch,
    Checkbox,
    Popup,
    InfoPopup,
} from "../../../components";
import localData from "../../../localData";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import useConvertKit from "../../../hooks/useConvertKit";
import { v4 as uuidv4 } from "uuid";

import { auth, db } from "../../../config/firebase";
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
} from "firebase/auth";
import { collection, getDoc, doc } from "firebase/firestore";

const { eye, eyeSlash, arrowLeft, logout } = localData.svgs;
const { logoDark, google, facebook, preloaderBig } = localData.images;

export default function Qualification() {
    const urlParams = new URLSearchParams(window.location.search);
    const refCode = urlParams.get("ref") || null;
    const [signupDisabled, setSignupDisable] = useState(false);
    const [errors, setErrors] = useState({});
    const { fade } = useGlobalContext().animations;
    const { _OrganizationAdditionalData, setIsAssistedCampaignEnabled } = useGlobalContext();

    const [isLoading, setIsLoading] = useState(false);

    const { register, checkEmailExists } = useFetch();
    const { sendConvertKit } = useConvertKit();
    const [qualificationState, setQualificationState] = useState({
        // step 1
        name: "",
        profession: "Brand (advertiser)",
        companyName: "",
        _category: "deFi",
        marketingBudget: "$10k-50k",

        // step 2
        websiteURL: "",
        _smartContractAddress: "",
        _chainTargeting: ["ETH", "BSC"],
        _walletTargeting: ["MetaMask", "Trust"],
        marketingGoal: ["sign ups", "trading volume"],
        AITargeting: true,
        isAdditionalDataFormCompleted: false,
    });

    useEffect(() => {
        console.log(qualificationState, " qualificationState");
    }, [qualificationState]);
    const [isAditionalFormLoading, setIsAditionalFormLoading] = useState(false);

    const callbackFromParent = () => {};

    const trackingTabs = [
        {
            title: "Step 1",
            content: <Step1 />,
            id: "qualification-step-1-eifoei2983yr23",
        },

        {
            title: "Step 3",
            content: <Step2 {...{ isAditionalFormLoading }} />,
            id: "qualification-step-3-eifoei2983yr23",
        },
    ];

    const handleAI = () => {
        setIsAssistedCampaignEnabled(qualificationState.AITargeting);
        localStorage.setItem("isAssistedCampaignEnabled", qualificationState.AITargeting);
    };

    useEffect(() => {
        if (!qualificationState.isAdditionalDataFormCompleted) return;
        _OrganizationAdditionalData({
            ...qualificationState,
            qualificationState,
            setQualificationState,
            setIsLoading: setIsAditionalFormLoading,
        });
        handleAI();
    }, [qualificationState]);

    return (
        <motion.main {...fade} className="main qualification-page">
            <div className="container">
                <img className="logo" src={logoDark} />
                <section className="qualification">
                    <div className="qualification-header"></div>
                    <Tabs
                        tabsProps={{ callbackFromParent, setQualificationState, qualificationState }}
                        tabs={trackingTabs}
                        title="Setup Tracking"
                        // indicatorIcon={question}
                        className="tracking-tabs"
                    />
                </section>
                <p className="copyright">
                    ©Blockchain-Ads. All rights reserved.
                    <Link to="/privacy-policy" underline="hover" className="link link-primary">
                        {" "}
                        Privacy Policy
                    </Link>
                </p>
            </div>
        </motion.main>
    );
}

const Step1 = ({ setActiveTab, activeTab, setQualificationState, qualificationState }) => {
    const [state, setState] = useState({
        name: qualificationState.name,
        profession: qualificationState.profession,
        companyName: qualificationState.companyName,
        _category: qualificationState._category,
        marketingBudget: qualificationState.marketingBudget,
    });

    const [isBtnDisabled, setIsBtnDisabled] = useState(false);

    const [errors, setErrors] = useState({
        nameError: false,
        // companyNameError: false,
        professionError: false,
        categoryError: false,
        marketingBudgetError: false,
    });

    const [professionOptions, setProfessionOptions] = useState([
        {
            title: "Brand (advertiser)",
            value: "",
            isSelected: false,
            id: uuidv4(),
        },
        {
            title: "Marketing Agency (Manage multiple brands)",
            value: "",
            isSelected: false,
            id: uuidv4(),
        },
        {
            title: "Affiliate (Run campaign for other brands independently - Company name below)",
            value: "",
            isSelected: false,
            id: uuidv4(),
        },
    ]);
    const [verticalsOptions, setVerticalsOptions] = useState([
        {
            title: "deFi",
            value: "deFi",
            propertyName: "deFi",
            isSelected: false,
            size: "1200000",
            id: uuidv4(),
        },
        {
            title: "Blockchain Gaming",
            value: "blockchainGaming",
            propertyName: "blockchainGaming",
            isSelected: false,
            size: "2400000",
            id: uuidv4(),
        },
        {
            title: "Marketplace",
            value: "marketplace",
            propertyName: "marketplace",
            isSelected: false,
            size: "2400000",
            id: uuidv4(),
        },
        {
            title: "Infrastructure",
            value: "infrastructure",
            propertyName: "infrastructure",
            isSelected: false,
            size: "4800000",
            id: uuidv4(),
        },
        { title: "NFT", propertyName: "NFT", value: "NFT", isSelected: false, size: "720000", id: uuidv4() },
        { title: "DEX", propertyName: "DEX", value: "DEX", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "CEX", propertyName: "CEX", value: "CEX", isSelected: false, size: "8000000", id: uuidv4() },
        // LATEST OPTIONS
        {
            title: "Betting & Gambling",
            value: "bettingAndgambling",
            propertyName: "bettingAndgambling",
            size: "0",
            isSelected: false,
            id: uuidv4(),
        },
        {
            title: "TradeFI",
            value: "tradeFi",
            propertyName: "tradeFi",
            size: "0",
            isSelected: false,
            id: uuidv4(),
        },
        // { title: "Crypto Trading", value: "cryptoTrading", isSelected: false, size: "4800000", id: uuidv4() },
        // { title: "Web3 Casino", value: "web3Casinos", isSelected: false, size: "1000000", id: uuidv4() },
        { title: "Other", value: "other", propertyName: "value", isSelected: false, size: "", id: uuidv4() },
    ]);

    const [marketingBudgetOptions, setMarketingBudgetOptions] = useState([
        { title: "$10k-50k", isSelected: false, id: uuidv4() },
        { title: "50-100", isSelected: false, id: uuidv4() },
        { title: "Above 100k ", isSelected: false, id: uuidv4() },
    ]);

    const { setRequestedData, handleSignOut } = useGlobalContext();

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    const setDefaultOptions = ({ property, state, setState, obj }) => {
        let tempState = [...state];
        tempState = tempState.map((item) => {
            const isSelected = (obj || qualificationState)[property].includes(item.value || item.title);
            return { ...item, isSelected };
        });
        setState(tempState);
    };

    useEffect(() => {
        const activeAudience = JSON.parse(localStorage.getItem("activeAudience"));
        if (activeAudience) {
            setState((prev) => {
                setDefaultOptions({
                    property: "_category",
                    state: verticalsOptions,
                    setState: setVerticalsOptions,
                    obj: {
                        ...prev,
                        _category: activeAudience,
                    },
                });
                return {
                    ...prev,
                    _category: activeAudience,
                };
            });
        } else {
            setDefaultOptions({
                property: "_category",
                state: verticalsOptions,
                setState: setVerticalsOptions,
            });
        }

        setDefaultOptions({
            property: "profession",
            state: professionOptions,
            setState: setProfessionOptions,
        });
        setDefaultOptions({
            property: "marketingBudget",
            state: marketingBudgetOptions,
            setState: setMarketingBudgetOptions,
        });
    }, []);

    useEffect(() => {
        setErrors((prev) => ({
            ...prev,
            nameError: state.name === "",
            // companyNameError: state.companyName === "",
            professionError: state.profession === "",
            categoryError: state._category === "",
            marketingBudgetError: state.marketingBudget === "",
        }));
    }, [state]);

    useEffect(() => {
        let haveError = false;
        Object.keys(errors).forEach((item) => {
            if (errors[item]) {
                haveError = true;
                return;
            }
        });
        setIsBtnDisabled(haveError);
    }, [errors]);

    // useEffect(()=>{
    //     setSelectDefaultOptions({
    //         selectedOptions: activeData.campaignType,
    //         state: campaignTypeOptions,
    //         setState: setCampaignTypeOptions,
    //     });
    // },[])
    // const setSelectDefaultOptions = ({ selectedOptions, state, setState }) => {
    //     let tempState = [...state];
    //     tempState = tempState.map((item) => {
    //         const isSelected = selectedOptions.includes(item.value || item.title);
    //         return { ...item, isSelected };
    //     });
    //     setState(tempState);
    // };
    return (
        <div className="step-2">
            <div className="form">
                <h2 className="form-title">Account Info</h2>
                <div className="step-scheme">
                    <div
                        className={`step-scheme-bar step-scheme-bar-left ${activeTab == 0 ? "active" : ""}`}
                    ></div>
                    <div
                        className={`step-scheme-bar step-scheme-bar-right ${activeTab == 1 ? "active" : ""}`}
                    ></div>
                </div>
                <h6 className="form-description">We use this info to customize your account</h6>
                <Field
                    type="text"
                    label="Name*"
                    color="secondary"
                    // placeholder="Enter your name"
                    required={true}
                    size="sm"
                    name="name"
                    value={state.name}
                    callback={handleOnChange}
                />

                <Select
                    {...{
                        items: professionOptions,
                        setItems: setProfessionOptions,
                        // placeholder: "Monthly marketing budget",
                        variant: "outlined",
                        color: "secondary",
                        label: "How would you best describe yourself*",
                        callback: (selectedItem) => {
                            setState((prev) => ({
                                ...prev,
                                profession: selectedItem.title,
                            }));
                        },
                    }}
                />

                <Field
                    size="sm"
                    type="text"
                    label="Company Name"
                    color="secondary"
                    // placeholder="Enter your company name"
                    required={true}
                    name="companyName"
                    value={state.companyName}
                    callback={handleOnChange}
                />

                <Select
                    {...{
                        items: verticalsOptions,
                        setItems: setVerticalsOptions,
                        // placeholder: "Monthly marketing budget",
                        variant: "outlined",
                        color: "secondary",
                        label: "Vertical you are advertising in*",
                        callback: (selectedItem) => {
                            setState((prev) => ({
                                ...prev,
                                _category: selectedItem.title,
                            }));
                        },
                    }}
                />

                <Select
                    {...{
                        items: marketingBudgetOptions,
                        setItems: setMarketingBudgetOptions,
                        // placeholder: "Monthly marketing budget",
                        variant: "outlined",
                        color: "secondary",
                        label: "Monthly marketing budget*",
                        callback: (selectedItem) => {
                            setState((prev) => ({
                                ...prev,
                                marketingBudget: selectedItem.title,
                            }));
                        },
                    }}
                />
                <br />

                {/* <div className="radio-multi-select">
                    <label htmlFor="" className="select-label">
                        Vertical you are advertising in*
                    </label>
                    {verticalsOptions.map((item, index) => {
                        return (
                            <Radio
                                key={index}
                                label={item.title}
                                checked={item.isSelected}
                                color="dark"
                                onClick={() => handleVerticals(item.title)}
                            />
                        );
                    })}
                </div>
                <br /> */}

                <div className="btn-group">
                    <Button
                        type="button"
                        className="submit-btn"
                        name="Log out"
                        color="danger"
                        // size="sm"
                        onClick={handleSignOut}
                        startIcon={logout}
                        icon={
                            false ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        disabled={false}
                    />
                    <Button
                        type="button"
                        className="submit-btn"
                        name="Continue"
                        color="success"
                        variant="contained"
                        // size="sm"
                        icon={
                            false ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        onClick={() => {
                            setQualificationState((prev) => ({
                                ...prev,
                                ...state,
                            }));
                            // setRequestedData((prev) => ({
                            //     ...prev,
                            //     // userProfileData: {...prev.userProfileData, isQualified: false},
                            //     isQualified: true,
                            // }));
                            // navigate("/");
                            setActiveTab(1);
                        }}
                        disabled={isBtnDisabled}
                    />
                </div>
            </div>
        </div>
    );
};

const Step2 = ({
    setActiveTab,
    activeTab,
    qualificationState,
    setQualificationState,
    isAditionalFormLoading,
}) => {
    const [state, setState] = useState({
        websiteURL: qualificationState.websiteURL,
        marketingGoal: qualificationState.marketingGoal,
        AITargeting: qualificationState.AITargeting,
        _chainTargeting: qualificationState._chainTargeting,
        _walletTargeting: qualificationState._walletTargeting,
        _smartContractAddress: qualificationState._smartContractAddress,
    });
    const [errors, setErrors] = useState({
        websiteURLError: false,
        marketingGoalError: false,
        // _smartContractAddressError: false,
    });

    const [chainTargetingOptions, setChainTargetingOptions] = useState([
        { title: "Custom", value: "custom", isSelected: false, size: "", id: uuidv4() },
        { title: "Ethereum", value: "ETH", isSelected: false, size: "8000000", id: uuidv4() },
        { title: "Binance Smart Chain", value: "BSC", isSelected: false, size: "1440000", id: uuidv4() },
        { title: "Polygon", value: "MATIC", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Solana", value: "SOL", isSelected: false, size: "1800000", id: uuidv4() },
        { title: "NEAR", value: "NEAR", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Arbitrum", value: "ARB", isSelected: false, size: "1200000", id: uuidv4() },
        { title: "Optimism", value: "OPT", isSelected: false, size: "720000", id: uuidv4() },
        { title: "Ripple", value: "XRP", isSelected: false, size: "1920000", id: uuidv4() },
        { title: "Cardano", value: "ADA", isSelected: false, size: "1680000", id: uuidv4() },
        { title: "Tron", value: "TRX", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Polkadot", value: "DOT", isSelected: false, size: "480000", id: uuidv4() },
        { title: "Avalanche", value: "AVAX", isSelected: false, size: "720000", id: uuidv4() },
        { title: "Stellar", value: "XLM", isSelected: false, size: "1680000", id: uuidv4() },
        { title: "Chainlink", value: "LINK", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Monero", value: "XMR", isSelected: false, size: "192000", id: uuidv4() },
        { title: "Cosmos", value: "ATOM", isSelected: false, size: "120000", id: uuidv4() },
        { title: "Hedera", value: "HBAR", isSelected: false, size: "120000", id: uuidv4() },
        { title: "Mantle", value: "MNT", isSelected: false, size: "60000", id: uuidv4() },
        { title: "Aptos", value: "APT", isSelected: false, size: "14400", id: uuidv4() },
        { title: "Chronos", value: "CHR", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Algorand", value: "ALGO", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Tezos", value: "XTZ", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Eos", value: "EOS", isSelected: false, size: "360000", id: uuidv4() },
        { title: "Partisia", value: "PRSS", isSelected: false, size: "480000", id: uuidv4() },
        { title: "Zksync", value: "ZKSYNC", isSelected: false, size: "12000", id: uuidv4() },
        { title: "Bitcoin", value: "BTC", isSelected: false, size: "6400000", id: uuidv4() },
        { title: "Base", value: "BASE", isSelected: false, size: "480000", id: uuidv4() },
    ]);

    const [walletTargetingOptions, setWalletTargetingOptions] = useState([
        { title: "MetaMask", isSelected: false, size: "4800000", id: uuidv4() },
        { title: "Trust", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "MEV", isSelected: false, size: "24000", id: uuidv4() },
        { title: "Coinomi", isSelected: false, size: "600000", id: uuidv4() },
        { title: "Coinbase", isSelected: false, size: "8000000", id: uuidv4() },
        { title: "OKX", isSelected: false, size: "3840000", id: uuidv4() },
        { title: "Exodus", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Rainbow", isSelected: false, size: "12000", id: uuidv4() },
        { title: "Argent", isSelected: false, size: "120000", id: uuidv4() },
        { title: "Safe", isSelected: false, size: "12000", id: uuidv4() },
        { title: "Zerion", isSelected: false, size: "84000", id: uuidv4() },
    ]);

    const [marketingGoalOptions, setMarketingGoalOptions] = useState([
        { title: "sign ups", value: "sign ups", isSelected: false, id: uuidv4() },
        { title: "deposits", value: "deposits", isSelected: false, id: uuidv4() },
        { title: "trading volume", value: "trading volume", isSelected: false, id: uuidv4() },
        { title: "other", value: "other", isSelected: false, id: uuidv4() },
    ]);

    const [marketingGoalOther, setMarketingGoalOther] = useState("other");

    const { setRequestedData } = useGlobalContext();

    const navigate = useNavigate();

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    function checkIsValidUrl(url) {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    }

    useEffect(() => {
        setErrors((prev) => ({
            ...prev,
            websiteURLError: state.websiteURL === "" || !checkIsValidUrl(state.websiteURL),
            marketingGoalError: !state.marketingGoal.length,
            // _smartContractAddressErrror:
            //     state._smartContractAddress === "" || !checkIsBlockchainAddress(state._smartContractAddress),
        }));
    }, [state]);

    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    useEffect(() => {
        let haveError = false;
        Object.keys(errors).forEach((item) => {
            if (errors[item]) {
                haveError = true;
                return;
            }
        });
        setIsBtnDisabled(haveError);
    }, [errors]);

    const handleMarketingGoal = (title = "") => {
        let options = [...marketingGoalOptions];
        options = options.map((item) => {
            if (item.title !== title) return { ...item };
            return {
                ...item,
                isSelected: !item.isSelected,
                value: item.title === "other" ? marketingGoalOther : item.value,
            };
        });
        setMarketingGoalOptions(options);
        setState({
            ...state,
            marketingGoal: options
                .filter((item) => item.isSelected)
                .map((item) => (item.title === "other" ? marketingGoalOther : item.value)),
        });
    };

    const handleMultiSelectChange = (items, name) => {
        let selectedItems = items?.filter((item) => item.isSelected);
        selectedItems = selectedItems?.map((item) => item.value || item.title);

        setState((state) => ({
            ...state,
            [name]: selectedItems,
        }));
    };

    function checkIsBlockchainAddress(text) {
        var pattern = /^0x[a-fA-F0-9]+$/;
        return pattern.test(text);
    }

    const setDefaultOptions = ({ property, state, setState, obj }) => {
        let tempState = [...state];
        tempState = tempState.map((item) => {
            const isSelected = (obj || qualificationState)[property].includes(item.value || item.title);
            return { ...item, isSelected };
        });
        setState(tempState);
    };

    useEffect(() => {
        setDefaultOptions({
            property: "_chainTargeting",
            state: chainTargetingOptions,
            setState: setChainTargetingOptions,
        });
        setDefaultOptions({
            property: "_walletTargeting",
            state: walletTargetingOptions,
            setState: setWalletTargetingOptions,
        });
        setDefaultOptions({
            property: "marketingGoal",
            state: marketingGoalOptions,
            setState: setMarketingGoalOptions,
        });
    }, []);

    return (
        <div className="step-2">
            <div className="form">
                <h2 className="form-title">Campaign details</h2>
                <div className="step-scheme">
                    <div
                        className={`step-scheme-bar step-scheme-bar-left ${activeTab == 0 ? "active" : ""}`}
                    ></div>
                    <div
                        className={`step-scheme-bar step-scheme-bar-right ${activeTab == 1 ? "active" : ""}`}
                    ></div>
                </div>
                <h6 className="form-description">We use this info to customize your account</h6>
                <Field
                    size="sm"
                    type="text"
                    label="Website URL*"
                    color="secondary"
                    // placeholder="Enter your company name"
                    required={true}
                    name="websiteURL"
                    value={state.websiteURL}
                    callback={handleOnChange}
                    errorMessage={
                        state.websiteURL !== "" && !checkIsValidUrl(state.websiteURL)
                            ? `Invalid URL. Please enter a valid URL. e.g.
                        <br /> https://johndoe.com`
                            : ""
                    }
                />
                <div className="checkbox-multi-select">
                    <label htmlFor="" className="select-label">
                        Marketing goal*
                    </label>
                    <br />
                    {marketingGoalOptions.map((item, index) => {
                        return (
                            <Checkbox
                                key={index}
                                callback={() => {
                                    handleMarketingGoal(item.title);
                                }}
                                checked={item.isSelected}
                                color="secondary"
                                size="sm"
                                label={item.title}
                                className={``}
                            >
                                {item.title == "other" && (
                                    <Field
                                        size="sm"
                                        type="text"
                                        color="secondary"
                                        // placeholder="Enter your company name"
                                        required={true}
                                        name="other"
                                        value={marketingGoalOther}
                                        callback={(e) => setMarketingGoalOther(e.target.value)}
                                        onBlur={handleMarketingGoal}
                                    />
                                )}
                            </Checkbox>
                        );
                    })}
                </div>
                <br />
                <hr style={{ borderColor: "rgba(0,0,0,0.2)", borderTop: "none", marginBottom: "5px" }} />
                <br />

                <h3 className="text-muted" style={{ fontSize: "18px", fontWeight: "600" }}>
                    For web3 campaigns
                </h3>
                <br />

                <Field
                    type="text"
                    label="Your token smart contract address"
                    color="secondary"
                    placeholder="e.g. 0x00000000000000"
                    // required={true}
                    size="sm"
                    name="_smartContractAddress"
                    value={state._smartContractAddress}
                    callback={handleOnChange}
                    errorMessage={
                        state._smartContractAddress !== "" &&
                        !checkIsBlockchainAddress(state._smartContractAddress)
                            ? `Invalid smart contract address.
                                <br /> e.g. 0x00000000000000`
                            : ""
                    }
                />

                <MultiSelect
                    {...{
                        items: chainTargetingOptions,
                        setItems: setChainTargetingOptions,
                        variant: "outlined",
                        limitTags: 3,
                        color: "secondary",
                        name: "_chainTargeting",
                        label: "Blockchains you support/are built on?",
                        callback: handleMultiSelectChange,
                    }}
                />
                <br />

                <MultiSelect
                    {...{
                        items: walletTargetingOptions,
                        setItems: setWalletTargetingOptions,
                        variant: "outlined",
                        limitTags: 3,
                        color: "secondary",
                        label: "Which wallets do you support?",
                        name: "_walletTargeting",
                        callback: handleMultiSelectChange,
                        // direction: window.innerWidth > 576 ? "up" : "down",
                    }}
                />
                <br />
                <br />

                <div className="switch-select">
                    <div className="wrapper">
                        <label htmlFor="" className="select-label">
                            Assisted AI targeting
                        </label>

                        <InfoPopup content="Blockchain-Ads will automatically select the best performing audiences based on your vertical and marketing goals. You will be able to change this option later" />
                    </div>
                    <Switch
                        disabled={false}
                        color="primary"
                        checked={state.AITargeting}
                        callback={() => {
                            setState((prev) => ({ ...prev, AITargeting: !state.AITargeting }));
                        }}
                    />
                </div>
                <br />
                <br />
                <br />

                <div className="btn-group">
                    <Button
                        type="button"
                        className="submit-btn"
                        name="Back"
                        color="dark"
                        startIcon={arrowLeft}
                        // size="sm"
                        onClick={() => {
                            setActiveTab(0);
                            // setQualificationState((prev) => ({
                            //     ...prev,
                            //     isAdditionalDataFormCompleted: false
                            // }));
                        }}
                        icon={
                            false ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        disabled={false}
                    />
                    <Button
                        type="button"
                        className="submit-btn"
                        name="Complete"
                        color="success"
                        variant="contained"
                        // size="sm"
                        icon={
                            isAditionalFormLoading ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        onClick={() => {
                            setQualificationState((prev) => ({
                                ...prev,
                                ...state,
                                isAdditionalDataFormCompleted: true,
                            }));
                        }}
                        disabled={isAditionalFormLoading || isBtnDisabled}
                    />
                </div>
            </div>
        </div>
    );
};
