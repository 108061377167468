import React, { useEffect, useState } from "react";
import {
    Field,
    Switch,
    Button,
    MultiSelect,
    Autocomplete,
    MultiSelectSearch,
    CountriesAndBid,
    Modal,
    EstimatedResults,
    AudienceEstimate,
} from "../../../components";
import localData from "../../../localData";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../../hooks/useFormatter";
import ReactDOMServer from "react-dom/server";
import Joyride, { STATUS } from "react-joyride";
import useCampaignWizardData from "../useCampaignWizardData";

const { budget, date, arrowLeft, arrowRight, close, settings, info, trashV2, exclamation, TargetingImage } = localData.svgs;

let timeout = "";

export default function Targeting({ setActiveTab, activeTab, setActiveStep }) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const {
        requestedData,
        campaignState,
        setCampaignState,
        campaignWizardStatus,
        isDarkModeEnabled,
        campaignsState,
        assistedCampaignData,
        isAssistedCampaignEnabled,
        setIsAssistedCampaignEnabled,
    } = useGlobalContext();

    const {
        categoryOptions,
        setCategoryOptions,
        walletTargetingOptions,
        setWalletTargetingOptions,
        web3AudienceOptions,
        setWeb3AudienceOptions,
        chainTargetingOptions,
        setChainTargetingOptions,
        countriesOptions,
        setCountriesOptions,
    } = useCampaignWizardData();

    const { addCommas, formatLargeNumber } = useFormatter();

    const { campaignTabs, setCampaignTabs } = campaignsState;

    // const [categoryOptions, setCategoryOptions] = useState([
    // {
    //     title: "Decentralized Finance",
    //     isSelected: false,
    //     size: "1200000",
    //     CTR1: "0.55%",
    //     CTR2: "1.23%",
    //     CVR1: "0.96%",
    //     CVR2: "1.1%",
    //     id: uuidv4(),
    // },
    // {
    //     title: "Blockchain Gaming",
    //     isSelected: false,
    //     size: "2400000",
    //     CTR1: "0.75%",
    //     CTR2: "1.75%",
    //     CVR1: "0.81%",
    //     CVR2: "1.53%",
    //     id: uuidv4(),
    // },
    // {
    //     title: "Marketplace",
    //     isSelected: false,
    //     size: "2400000",
    //     CTR1: "0.57%",
    //     CTR2: "1.48%",
    //     CVR1: "0.97%",
    //     CVR2: "1.03%",
    //     id: uuidv4(),
    // },
    // {
    //     title: "Infrastructure",
    //     isSelected: false,
    //     size: "4800000",
    //     CTR1: "0.7%",
    //     CTR2: "1.93%",
    //     CVR1: "0.87%",
    //     CVR2: "1.3%",
    //     id: uuidv4(),
    // },
    // {
    //     title: "NFT",
    //     isSelected: false,
    //     size: "720000",
    //     CTR1: "0.71%",
    //     CTR2: "1.08%",
    //     CVR1: "0.75%",
    //     CVR2: "1.23%",
    //     id: uuidv4(),
    // },
    // {
    //     title: "DEX",
    //     isSelected: false,
    //     size: "2400000",
    //     CTR1: "0.85%",
    //     CTR2: "1.4%",
    //     CVR1: "0.61%",
    //     CVR2: "1.17%",
    //     id: uuidv4(),
    // },
    // {
    //     title: "CEX",
    //     isSelected: false,
    //     size: "8000000",
    //     CTR1: "0.86%",
    //     CTR2: "1.8%",
    //     CVR1: "0.72%",
    //     CVR2: "1.11%",
    //     id: uuidv4(),
    // },
    // // LATEST OPTIONS
    // {
    //     title: "Crypto Trading",
    //     isSelected: false,
    //     size: "4800000",
    //     CTR1: "0.58%",
    //     CTR2: "1.07%",
    //     CVR1: "1%",
    //     CVR2: "1.79%",
    //     id: uuidv4(),
    // },
    // {
    //     title: "Web3 Casino",
    //     isSelected: false,
    //     size: "1000000",
    //     CTR1: "0.74%",
    //     CTR2: "1.55%",
    //     CVR1: "0.99%",
    //     CVR2: "1.16%",
    //     id: uuidv4(),
    // },
    // {
    //     title: "Other",
    //     isSelected: false,
    //     size: "",
    //     CTR1: "000",
    //     CTR2: "000",
    //     CVR1: "000",
    //     CVR2: "000",
    //     id: uuidv4(),
    // },
    // ]);

    // const [web3AudienceOptions, setWeb3AudienceOptions] = useState([
    //     { title: "Custom", isSelected: false, size: "", id: uuidv4() },
    //     { title: "BTC Holders", isSelected: false, size: "6400000", id: uuidv4() },
    //     { title: "ETH Holders", isSelected: false, size: "8000000", id: uuidv4() },
    //     { title: "MATIC Holders", isSelected: false, size: "2400000", id: uuidv4() },
    //     { title: "NEAR Holders", isSelected: false, size: "2400000", id: uuidv4() },
    //     { title: "SOL Holders", isSelected: false, size: "1800000", id: uuidv4() },
    //     { title: "BNB Holders", isSelected: false, size: "1440000", id: uuidv4() },
    //     { title: "Stable Coin Holders", isSelected: false, size: "6000000", id: uuidv4() },
    //     { title: "DeFi", isSelected: false, size: "1200000", id: uuidv4() },
    //     { title: "NFT Collectors", isSelected: false, size: "1440000", id: uuidv4() },
    //     { title: "Web3 Natives", isSelected: false, size: "2400000", id: uuidv4() },
    //     { title: "Blockchain Technology", isSelected: false, size: "8000000", id: uuidv4() },
    //     { title: "Crypto Whales", isSelected: false, size: "240000", id: uuidv4() },
    //     { title: "IDO/ICO Participants", isSelected: false, size: "2400000", id: uuidv4() },
    //     { title: "Tokenized Assets Holders", isSelected: false, size: "3600000", id: uuidv4() },
    //     // LATEST OPTIONS
    //     { title: "Crypto Traders", isSelected: false, size: "24000000", id: uuidv4() },
    //     { title: "Casino Players", isSelected: false, size: "1200000", id: uuidv4() },
    //     { title: "Casinos Power Users", isSelected: false, size: "240000", id: uuidv4() },
    //     { title: "Sports Betting", isSelected: false, size: "1200000", id: uuidv4() },
    //     { title: "PlayToEarn", isSelected: false, size: "4800000", id: uuidv4() },
    //     { title: "HOLDers", isSelected: false, size: "12000000", id: uuidv4() },
    //     { title: "Crypto News", isSelected: false, size: "7200000", id: uuidv4() },
    //     { title: "DeFi Trading", isSelected: false, size: "3600000", id: uuidv4() },
    //     { title: "Finance Users", isSelected: false, size: "7200000", id: uuidv4() },
    //     { title: "DeFi News", isSelected: false, size: "2400000", id: uuidv4() },
    //     { title: "NFT Traders", isSelected: false, size: "2400000", id: uuidv4() },
    //     { title: "NFT Whales", isSelected: false, size: "120000", id: uuidv4() },
    //     { title: "NFT Marketplaces", isSelected: false, size: "3600000", id: uuidv4() },
    //     { title: "Blockchain Gaming", isSelected: false, size: "4800000", id: uuidv4() },
    //     { title: "Web3 Gamers", isSelected: false, size: "4320000", id: uuidv4() },
    // ]);

    // const [chainTargetingOptions, setChainTargetingOptions] = useState([
    //     { title: "Custom", value: "custom", isSelected: false, size: "", id: uuidv4() },
    //     { title: "Ethereum", value: "ETH", isSelected: false, size: "8000000", id: uuidv4() },
    //     { title: "Binance Smart Chain", value: "BSC", isSelected: false, size: "1440000", id: uuidv4() },
    //     { title: "Polygon", value: "MATIC", isSelected: false, size: "2400000", id: uuidv4() },
    //     { title: "Solana", value: "SOL", isSelected: false, size: "1800000", id: uuidv4() },
    //     { title: "NEAR", value: "NEAR", isSelected: false, size: "2400000", id: uuidv4() },
    //     { title: "Arbitrum", value: "ARB", isSelected: false, size: "1200000", id: uuidv4() },
    //     { title: "Optimism", value: "OPT", isSelected: false, size: "720000", id: uuidv4() },
    //     { title: "Ripple", value: "XRP", isSelected: false, size: "1920000", id: uuidv4() },
    //     { title: "Cardano", value: "ADA", isSelected: false, size: "1680000", id: uuidv4() },
    //     { title: "Tron", value: "TRX", isSelected: false, size: "2400000", id: uuidv4() },
    //     { title: "Polkadot", value: "DOT", isSelected: false, size: "480000", id: uuidv4() },
    //     { title: "Avalanche", value: "AVAX", isSelected: false, size: "720000", id: uuidv4() },
    //     { title: "Stellar", value: "XLM", isSelected: false, size: "1680000", id: uuidv4() },
    //     { title: "Chainlink", value: "LINK", isSelected: false, size: "240000", id: uuidv4() },
    //     { title: "Monero", value: "XMR", isSelected: false, size: "192000", id: uuidv4() },
    //     { title: "Cosmos", value: "ATOM", isSelected: false, size: "120000", id: uuidv4() },
    //     { title: "Hedera", value: "HBAR", isSelected: false, size: "120000", id: uuidv4() },
    //     { title: "Mantle", value: "MNT", isSelected: false, size: "60000", id: uuidv4() },
    //     { title: "Aptos", value: "APT", isSelected: false, size: "14400", id: uuidv4() },
    //     { title: "Chronos", value: "CHR", isSelected: false, size: "240000", id: uuidv4() },
    //     { title: "Algorand", value: "ALGO", isSelected: false, size: "2400000", id: uuidv4() },
    //     { title: "Tezos", value: "XTZ", isSelected: false, size: "240000", id: uuidv4() },
    //     { title: "Eos", value: "EOS", isSelected: false, size: "360000", id: uuidv4() },
    //     { title: "Partisia", value: "PRSS", isSelected: false, size: "480000", id: uuidv4() },
    //     { title: "Zksync", value: "ZKSYNC", isSelected: false, size: "12000", id: uuidv4() },
    //     { title: "Bitcoin", value: "BTC", isSelected: false, size: "6400000", id: uuidv4() },
    //     { title: "Base", value: "BASE", isSelected: false, size: "480000", id: uuidv4() },
    // ]);

    // const [walletTargetingOptions, setWalletTargetingOptions] = useState([
    //     { title: "MetaMask", isSelected: false, size: "4800000", id: uuidv4() },
    //     { title: "Trust", isSelected: false, size: "2400000", id: uuidv4() },
    //     { title: "MEV", isSelected: false, size: "24000", id: uuidv4() },
    //     { title: "Coinomi", isSelected: false, size: "600000", id: uuidv4() },
    //     { title: "Coinbase", isSelected: false, size: "8000000", id: uuidv4() },
    //     { title: "OKX", isSelected: false, size: "3840000", id: uuidv4() },
    //     { title: "Exodus", isSelected: false, size: "240000", id: uuidv4() },
    //     { title: "Rainbow", isSelected: false, size: "12000", id: uuidv4() },
    //     { title: "Argent", isSelected: false, size: "120000", id: uuidv4() },
    //     { title: "Safe", isSelected: false, size: "12000", id: uuidv4() },
    //     { title: "Zerion", isSelected: false, size: "84000", id: uuidv4() },
    // ]);

    const [deviceTypeOptions, setDeviceTypeOptions] = useState([
        { title: "Mobile", isSelected: false, percentage: "58.21", id: uuidv4() },
        { title: "Desktop", isSelected: false, percentage: "41.79", id: uuidv4() },
    ]);

    const [operativeSystemOptions, setOperativeSystemOptions] = useState([
        { title: "Android", isSelected: false, percentage: "41.00", id: uuidv4() },
        { title: "Windows", isSelected: false, percentage: "40.00", id: uuidv4() },
        { title: "IOS", isSelected: false, percentage: "17.00", id: uuidv4() },
        { title: "Linux", isSelected: false, percentage: "2.00", id: uuidv4() },
        { title: "macOS", isSelected: false, percentage: "10.00", id: uuidv4() },
    ]);

    const [browserOptions, setBrowserOptions] = useState([
        { title: "Google Chrome", isSelected: false, percentage: "64.75", id: uuidv4() },
        { title: "Safari", isSelected: false, percentage: "19.66", id: uuidv4() },
        { title: "Microsoft Edge", isSelected: false, percentage: "4.32", id: uuidv4() },
        { title: "Firefox", isSelected: false, percentage: "2.98", id: uuidv4() },
        { title: "Opera", isSelected: false, percentage: "2.40", id: uuidv4() },
        { title: "Other", isSelected: false, percentage: "5.89", id: uuidv4() },
    ]);

    const setDefaultOptions = ({ property, state, setState }) => {
        let tempState = [...state];
        tempState = tempState.map((item) => {
            const isSelected = campaignState.campaignInfo[property].includes(item.value || item.title);
            return { ...item, isSelected };
        });
        setState(tempState);
    };

    const setAdditionalFormOptions = ({ property, state, setState }) => {
        let tempState = [...state];
        tempState = tempState.map((item) => {
            const isSelected = campaignState.campaignInfo[property].includes(item.value || item.title);
            return { ...item, isSelected };
        });
        setState(tempState);
    };

    const setDefaultMultiSelectItems = () => {
        setDefaultOptions({ property: "category", state: categoryOptions, setState: setCategoryOptions });
        setDefaultOptions({
            property: "web3Audience",
            state: web3AudienceOptions,
            setState: setWeb3AudienceOptions,
        });
        setDefaultOptions({
            property: "chainTargeting",
            state: chainTargetingOptions,
            setState: setChainTargetingOptions,
        });

        setDefaultOptions({
            property: "walletTargeting",
            state: walletTargetingOptions,
            setState: setWalletTargetingOptions,
        });
        setDefaultOptions({
            property: "deviceType",
            state: deviceTypeOptions,
            setState: setDeviceTypeOptions,
        });
        setDefaultOptions({
            property: "operativeSystem",
            state: operativeSystemOptions,
            setState: setOperativeSystemOptions,
        });
        setDefaultOptions({
            property: "browser",
            state: browserOptions,
            setState: setBrowserOptions,
        });
        setDefaultOptions({
            property: "countriesTargeting",
            state: countriesOptions,
            setState: setCountriesOptions,
        });

        // for countries and bid
        setState((state) => ({
            ...state,
            countriesTargeting: campaignState.campaignInfo.countriesTargeting,
        }));
    };

    useEffect(() => {
        if (campaignWizardStatus === "edit") {
            setDefaultMultiSelectItems();
        }
    }, []);

    // useEffect(() => {
    //     if (campaignWizardStatus === "create") {
    //         const { websiteURL } = requestedData.userProfileData.currentOrganization.additionalData;
    //         console.log(websiteURL);
    //         // setAdditionalFormOptions
    //         if (websiteURL) {
    //         }
    //     }
    // }, [requestedData.userProfileData]);

    const [allEstimatedSizes, setAllEstimatedSizes] = useState({
        categoryEstimatedSize: 0,
        web3AudienceEstimatedSize: 0,
        walletTargetingEstimatedSize: 0,
        chainTargetingEstimatedSize: 0,
        countriesEstimatedSize: 0,
    });

    const [estimatedSize, setEstimatedSize] = useState({ smallestEstimateSize: 0, largestEstimateSize: 0 });
    const [isEstimatedSizeLoading, setIsEstimatedSizeLoading] = useState(false);
    const [isUnableToEstimate, setIsUnableToEstimate] = useState(false);

    const [estimatedPercentage, setEstimatedPercentage] = useState({
        smallestEstimatePercentage: 0,
        largestEstimatePercentage: 0,
    });
    const [allEstimatedPercentages, setAllEstimatedPercentages] = useState({
        deviceTypeEstimatedPercentage: 0,
        operativeSystemPercentage: 0,
        browserPercentage: 0,
    });

    const concat = (options) => {
        let concatedValue = 0;
        options.forEach((item) => {
            if (!item.isSelected) return;
            concatedValue += parseFloat(item.size) || parseFloat(item.percentage) || 0;
        });

        return concatedValue;
    };

    const getFilteredSmallestEstimatePercentage = ({ smallestEstimatePercentage }) => {
        const deviceType = deviceTypeOptions.filter((item) => item.isSelected).map((item) => item.title);
        const os = operativeSystemOptions.filter((item) => item.isSelected).map((item) => item.title);
        const browser = browserOptions.filter((item) => item.isSelected).map((item) => item.title);

        setIsUnableToEstimate((prev) => false);

        if (
            (os.includes("Android") || os.includes("Windows")) &&
            (browser.includes("Safari") || browser.includes("Opera"))
        ) {
            setIsUnableToEstimate((prev) => true);
            return 0;
        }
        if (os.includes("IOS") && (browser.includes("Google Chrome") || browser.includes("Microsoft Edge"))) {
            setIsUnableToEstimate((prev) => true);
            return 0;
        }
        if (
            (os.includes("Windows") || os.includes("Linux") || os.includes("macOS")) &&
            deviceType.includes("Mobile")
        ) {
            setIsUnableToEstimate((prev) => true);
            return 0;
        }
        if (os.includes("Android") && deviceType.includes("Desktop")) {
            setIsUnableToEstimate((prev) => true);
            return 0;
        }

        // if (
        //     os.length === 1 &&
        //     (os.includes("Android") || os.includes("Windows")) &&
        //     browser.length === 1 &&
        //     (browser.includes("Safari") || browser.includes("Opera"))
        // ) {
        //     setIsUnableToEstimate((prev)=>true)
        //     return 0;
        // }
        // if (
        //     os.length === 1 &&
        //     os.includes("IOS") &&
        //     browser.length === 1 &&
        //     (browser.includes("Google Chrome") || browser.includes("Microsoft Edge"))
        // ) {
        //     setIsUnableToEstimate((prev)=>true)
        //     return 0;
        // }
        // if (
        //     os.length === 1 &&
        //     (os.includes("Windows") || os.includes("Linux") || os.includes("macOS")) &&
        //     deviceType.length === 1 &&
        //     deviceType.includes("Mobile")
        // ) {
        //     setIsUnableToEstimate((prev)=>true)
        //     return 0;
        // }
        // if (
        //     os.length === 1 &&
        //     os.includes("Android") &&
        //     deviceType.length === 1 &&
        //     deviceType.includes("Desktop")
        // ) {
        //     setIsUnableToEstimate((prev)=>true)
        //     return 0;
        // }

        return smallestEstimatePercentage;
    };

    const [result, setResult] = useState(0);

    const handleEstimatedSize = () => {
        // let categoryEstimatedSize = 0;
        // let web3AudienceEstimatedSize = 0;
        // let walletTargetingEstimatedSize = 0;
        // let chainTargetingEstimatedSize = 0;
        // let countriesEstimatedSize = 0;

        // categoryOptions.forEach((item) => {
        //     if (!item.isSelected) return;
        //     categoryEstimatedSize += parseInt(item.size) || 0;
        // });
        // web3AudienceOptions.forEach((item) => {
        //     if (!item.isSelected) return;
        //     web3AudienceEstimatedSize += parseInt(item.size) || 0;
        // });
        // walletTargetingOptions.forEach((item) => {
        //     if (!item.isSelected) return;
        //     walletTargetingEstimatedSize += parseInt(item.size) || 0;
        // });
        // chainTargetingOptions.forEach((item) => {
        //     if (!item.isSelected) return;
        //     chainTargetingEstimatedSize += parseInt(item.size) || 0;
        // });
        // countriesOptions.forEach((item) => {
        //     if (!item.isSelected) return;
        //     countriesEstimatedSize += parseInt(item.size) || 0;
        // });

        //SIZES

        const categoryEstimatedSize = concat(categoryOptions);
        const web3AudienceEstimatedSize = concat(web3AudienceOptions);
        const walletTargetingEstimatedSize = concat(walletTargetingOptions);
        const chainTargetingEstimatedSize = concat(chainTargetingOptions);
        const countriesEstimatedSize = concat(countriesOptions);

        const allSizes = {
            categoryEstimatedSize,
            web3AudienceEstimatedSize,
            walletTargetingEstimatedSize,
            chainTargetingEstimatedSize,
            countriesEstimatedSize,
        };

        const nonZeroSizes = Object.values(allSizes).filter((size) => size !== 0);
        let smallestEstimateSize = nonZeroSizes.length > 0 ? Math.min(...nonZeroSizes) : 0;
        let largestEstimateSize = nonZeroSizes.length > 0 ? Math.max(...nonZeroSizes) : 0;

        // if (largestEstimateSize < 32300000) largestEstimateSize = 32300000;
        // if (smallestEstimateSize < 29200000 || smallestEstimateSize == largestEstimateSize)
        //     smallestEstimateSize = 29200000;

        const sizes = { smallestEstimateSize, largestEstimateSize };

        setEstimatedSize(sizes);
        setAllEstimatedSizes(allSizes);

        // PERCENTAGES

        const deviceTypeEstimatedPercentage = concat(deviceTypeOptions);
        const operativeSystemPercentage = concat(operativeSystemOptions);
        const browserPercentage = concat(browserOptions);

        const allPercentages = {
            deviceTypeEstimatedPercentage,
            operativeSystemPercentage,
            browserPercentage,
        };

        const nonZeroPercentages = Object.values(allPercentages).filter((percentage) => percentage !== 0);
        let smallestEstimatePercentage = nonZeroPercentages.length > 0 ? Math.min(...nonZeroPercentages) : 0;
        let largestEstimatePercentage = nonZeroPercentages.length > 0 ? Math.max(...nonZeroPercentages) : 0;

        const percentages = { smallestEstimatePercentage, largestEstimatePercentage };

        setEstimatedPercentage(percentages);
        setAllEstimatedPercentages(allPercentages);

        smallestEstimatePercentage = getFilteredSmallestEstimatePercentage({ smallestEstimatePercentage });

        let result = smallestEstimateSize;

        if (smallestEstimatePercentage) {
            result = (smallestEstimateSize * smallestEstimatePercentage) / 100;
        }

        setResult(result);
    };

    useEffect(() => {
        if (!isEstimatedSizeLoading) return;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            setIsEstimatedSizeLoading(false);
        }, 3000);
    }, [estimatedSize]);

    useEffect(() => {
        setIsEstimatedSizeLoading(true);

        handleEstimatedSize();
    }, [
        categoryOptions,
        web3AudienceOptions,
        chainTargetingOptions,
        walletTargetingOptions,
        countriesOptions,

        deviceTypeOptions,
        operativeSystemOptions,
        browserOptions,
    ]);

    const handleMultiSelectChange = (items, name) => {
        let selectedItems = items?.filter((item) => item.isSelected);
        selectedItems = selectedItems?.map((item) => item.value || item.title);

        setState((state) => ({
            ...state,
            [name]: selectedItems,
        }));
    };

    const [state, setState] = useState({
        category: [],
        web3Audience: [],
        chainTargeting: [],

        walletTargeting: [],
        deviceType: [],
        operativeSystem: [],
        browser: [],
        countriesTargeting: [],
    });

    const [errors, setErrors] = useState({
        categoryError: false,
        web3AudienceError: false,
        chainTargetingError: false,
        walletTargetingError: false,
        deviceTypeError: false,
        operativeSystemError: false,
        browserError: false,
        countriesTargetingError: false,
    });

    useEffect(() => {
        setErrors({
            ...errors,
            categoryError: !state.category.length,
            web3AudienceError: !state.web3Audience.length,
            chainTargetingError: !state.chainTargeting.length,
            walletTargetingError: !state.walletTargeting.length,
            deviceTypeError: !state.deviceType.length,
            operativeSystemError: !state.operativeSystem.length,
            browserError: !state.browser.length,
            countriesTargetingError: !state.countriesTargeting.length,
        });
    }, [state]);

    useEffect(() => {
        const haveError = true;
        // !errors.categoryError &&
        // !errors.web3AudienceError &&
        // !errors.chainTargetingError &&
        // !errors.walletTargetingError &&
        // !errors.deviceTypeError &&
        // !errors.operativeSystemError &&
        // !errors.browserError &&
        // !errors.countriesTargetingError

        setIsButtonDisabled(!haveError);
    }, [errors]);

    const [displayGeoModal, setDisplayGeoModal] = useState("none");

    const resetParameters = () => {
        setState({
            category: [],
            web3Audience: [],
            chainTargeting: [],
            walletTargeting: [],
            deviceType: [],
            operativeSystem: [],
            browser: [],
            countriesTargeting: [],
        });

        setCategoryOptions(categoryOptions.map((item) => ({ ...item, isSelected: false })));
        setWeb3AudienceOptions(web3AudienceOptions.map((item) => ({ ...item, isSelected: false })));
        setChainTargetingOptions(chainTargetingOptions.map((item) => ({ ...item, isSelected: false })));
        setWalletTargetingOptions(walletTargetingOptions.map((item) => ({ ...item, isSelected: false })));
        setDeviceTypeOptions(deviceTypeOptions.map((item) => ({ ...item, isSelected: false })));
        setOperativeSystemOptions(operativeSystemOptions.map((item) => ({ ...item, isSelected: false })));
        setBrowserOptions(browserOptions.map((item) => ({ ...item, isSelected: false })));
        setCountriesOptions(countriesOptions.map((item) => ({ ...item, isSelected: false })));
        // setActiveAssistedCampaignData({});
        setIsAssistedCampaignEnabled(false);
    };

    const setAssistedDataOptions = ({ selectedOptions, state, setState }) => {
        let tempState = [...state];
        tempState = tempState.map((item) => {
            const isSelected = selectedOptions.includes(item.value || item.title);
            return { ...item, isSelected };
        });
        setState(tempState);
    };

    const [activeAssistedCampaignData, setActiveAssistedCampaignData] = useState({});
    const [activeAdditionalData, setActiveAdditionalData] = useState({});

    useEffect(() => {
        if (!isAssistedCampaignEnabled) return;
        if (campaignWizardStatus === "edit") return;
        if (!assistedCampaignData.length) return;

        const activeData = assistedCampaignData.find((item) => item.isSelected);
        if (!activeData) return;

        setState((prev) => ({
            ...prev,
            category: activeData.category,
            web3Audience: activeData.web3Audience,
        }));

        setAssistedDataOptions({
            selectedOptions: activeData.category,
            state: categoryOptions,
            setState: setCategoryOptions,
        });
        setAssistedDataOptions({
            selectedOptions: activeData.web3Audience,
            state: web3AudienceOptions,
            setState: setWeb3AudienceOptions,
        });
        setAssistedDataOptions({
            selectedOptions: activeData.chainTargeting,
            state: chainTargetingOptions,
            setState: setChainTargetingOptions,
        });
        setAssistedDataOptions({
            selectedOptions: activeData.walletTargeting,
            state: walletTargetingOptions,
            setState: setWalletTargetingOptions,
        });
        setAssistedDataOptions({
            selectedOptions: activeData.category,
            state: categoryOptions,
            setState: setCategoryOptions,
        });
        setAssistedDataOptions({
            selectedOptions: activeData.operativeSystem,
            state: operativeSystemOptions,
            setState: setOperativeSystemOptions,
        });
        setAssistedDataOptions({
            selectedOptions: activeData.browser,
            state: browserOptions,
            setState: setBrowserOptions,
        });
        setAssistedDataOptions({
            selectedOptions: activeData.deviceType,
            state: deviceTypeOptions,
            setState: setDeviceTypeOptions,
        });
        setAssistedDataOptions({
            selectedOptions: activeData.geo,
            state: countriesOptions,
            setState: setCountriesOptions,
        });

        // for countries and bid
        setState((state) => ({
            ...state,
            countriesTargeting: activeData.geo,
        }));
    }, [assistedCampaignData, isAssistedCampaignEnabled]);

    return (
        <>
            <TargetingJoyride activeTab={activeTab} />
            <div className="targeting">
                <div className="wrapper ">
                    <p className="targeting-description">
                        {" "}
                        For assistance on targeting, contact the Blockchain-Ads team via Telegram at{" "}
                        <a
                            href="https://t.me/BlockchainAds_Support"
                            underline="hover"
                            className="link link-primary"
                            target="_blank"
                        >
                            https://t.me/BlockchainAds_Support
                        </a>{" "}
                        or via email at{" "}
                        <a
                            href="mailto:contact@blockchain-ads.com"
                            underline="hover"
                            className="link link-primary"
                            target="_blank"
                        >
                            contact@blockchain-ads.com
                        </a>
                    </p>
                    <div
                        className={`row row-top  `}
                        // className={`row row-top ${campaignWizardStatus === "edit" ? "not-editable" : ""} `}
                        // data-tooltip-id="tooltip-targeting"
                        // data-tooltip-content="Not Editable"
                    >
                        <div className="fields-group fields-group-top wizard-block" data-guide="targeting-tutorial-1">
                            <MultiSelect
                                {...{
                                    items: categoryOptions,
                                    setItems: setCategoryOptions,
                                    variant: "outlined",
                                    limitTags: 2,
                                    color: "secondary",
                                    label: "Category",
                                    labelInfo: "(Optional) Select your project’s vertical.",
                                    name: "category",
                                    callback: handleMultiSelectChange,
                                }}
                            />
                            <MultiSelect
                                {...{
                                    items: walletTargetingOptions,
                                    setItems: setWalletTargetingOptions,
                                    variant: "outlined",
                                    limitTags: 2,
                                    color: "secondary",
                                    label: "Wallet Targeting",
                                    labelInfo: "(Optional) Target specific wallet users.",
                                    name: "walletTargeting",
                                    callback: handleMultiSelectChange,
                                    // direction: window.innerWidth > 576 ? "up" : "down",
                                }}
                            />
                            <MultiSelect
                                {...{
                                    items: web3AudienceOptions,
                                    setItems: setWeb3AudienceOptions,
                                    variant: "outlined",
                                    limitTags: 2,
                                    color: "secondary",
                                    name: "web3Audience",
                                    label: "Web3 Audiences",
                                    labelInfo: "Pick your campaign targeting among the preset Audiences.",
                                    callback: handleMultiSelectChange,
                                }}
                            />
                            <MultiSelect
                                {...{
                                    items: chainTargetingOptions,
                                    setItems: setChainTargetingOptions,
                                    variant: "outlined",
                                    limitTags: 2,
                                    color: "secondary",
                                    name: "chainTargeting",
                                    label: "Chain Targeting",
                                    labelInfo: "Pick your campaign targeting.",
                                    callback: handleMultiSelectChange,
                                }}
                            />
                        </div>
                        <br />
                        <br />
                        <div className="fields-group fields-group-bottom wizard-block" data-guide="targeting-tutorial-2">
                            <MultiSelect
                                {...{
                                    items: operativeSystemOptions,
                                    setItems: setOperativeSystemOptions,
                                    variant: "outlined",
                                    limitTags: 2,
                                    color: "secondary",
                                    label: "Operative System",
                                    labelInfo: "(Optional) Choose specific operating systems to target.",
                                    name: "operativeSystem",
                                    callback: handleMultiSelectChange,
                                }}
                            />

                            <MultiSelect
                                {...{
                                    items: browserOptions,
                                    setItems: setBrowserOptions,
                                    variant: "outlined",
                                    limitTags: 2,
                                    color: "secondary",
                                    label: "Browser",
                                    labelInfo: "Choose specific browsers to target.",
                                    name: "browser",
                                    callback: handleMultiSelectChange,
                                }}
                            />
                            <Modal
                                // overlayClickDisabled={true}
                                restProps={{
                                    state,
                                    setState,
                                    items: countriesOptions,
                                    setItems: setCountriesOptions,
                                    estimatedSize,
                                    isEstimatedSizeLoading,
                                    result,
                                    isUnableToEstimate,
                                }}
                                Toggler={() => (
                                    // <Button
                                    //     className="geo-modal-toggler"
                                    //     name="Geo"
                                    //     endIcon={settings}
                                    //     onClick={() => setDisplayGeoModal("block")}
                                    //     color="secondary"
                                    //     variant="outlined"
                                    // />
                                    <MultiSelect
                                        {...{
                                            items: countriesOptions,
                                            setItems: setCountriesOptions,
                                            variant: "outlined",
                                            color: "secondary",
                                            label: "Geo",
                                            name: "countriesTargeting",
                                            labelInfo: "(Optional) Choose specific countries to target.",
                                            toggleIcon: settings,
                                            // callback: handleMultiSelectChange,
                                            useAsButton: true,
                                            limitTags: 2,
                                            buttonCallback: () => setDisplayGeoModal("block"),
                                        }}
                                    />
                                )}
                                // modalCloseCallback={modalCloseCallback}
                                display={displayGeoModal}
                                setDisplay={setDisplayGeoModal}
                                className="modal-dialog-centered geo-modal"
                                Child={Geo}
                            />
                            <MultiSelect
                                {...{
                                    withCheckbox: false,
                                    items: deviceTypeOptions,
                                    setItems: setDeviceTypeOptions,
                                    variant: "outlined",
                                    limitTags: 2,
                                    color: "secondary",
                                    label: "Device Type",
                                    labelInfo: "(Optional) Choose Mobile or Desktop.",
                                    name: "deviceType",
                                    callback: handleMultiSelectChange,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="targeting-block">
                    <TargetingImage />
                </div>
                {/* <div className="estimates-wrapper">
              
                    <EstimatedResults
                        {...{
                            categoryOptions,
                            walletTargetingOptions,
                            web3AudienceOptions,
                            chainTargetingOptions,
                            result,
                            isLoading: isEstimatedSizeLoading,
                            error: isUnableToEstimate
                        }}
                        campaignState={campaignState}
                    />
                </div> */}
            </div>
            <div className="row row-bottom btn-group">
                {campaignWizardStatus == "create" ? (
                    <Button
                        size="sm"
                        disabled={false}
                        name="Reset Parameters"
                        color={"dark"}
                        startIcon={trashV2}
                        onClick={resetParameters}
                        style={{ marginRight: "auto" }}
                    />
                ) : (
                    ""
                )}
                <Button
                    size="sm"
                    startIcon={arrowLeft}
                    name="Back"
                    variant="text"
                    color="dark"
                    // onClick={() => setActiveTab((prevNumber) => prevNumber - 1)}
                    onClick={() => {
                        setActiveTab(0);
                    }}
                />
                <Button
                    size="sm"
                    name="Save & Continue"
                    dataGuide="targeting-tutorial-3"
                    variant="contained"
                    color={isDarkModeEnabled ? "light" : "dark"}
                    disabled={isButtonDisabled}
                    onClick={() => {
                        setCampaignState((prev) => {
                            return {
                                ...prev,
                                campaignInfo: {
                                    ...prev.campaignInfo,
                                    ...state,
                                    // chainTargeting: state.chainTargeting.length
                                    //     ? state.chainTargeting
                                    //     : ["BSC"],
                                },
                            };
                        });
                        setActiveTab((prevNumber) => prevNumber + 1);

                        setCampaignTabs({
                            ...campaignTabs,
                            clickable: [...campaignTabs.clickable, 2],
                            marked: [...campaignTabs.marked, 2],
                        });
                    }}
                />
            </div>
            <br />
            <br />
            <br />
        </>
    );
}

const Geo = ({
    setIsOpen,
    state,
    setState,
    items,
    setItems,
    estimatedSize,
    isEstimatedSizeLoading,
    result,
    isUnableToEstimate,
}) => {
    const closeModal = () => {
        setIsOpen(false);
    };
    const { close } = localData.svgs;
    const { map } = localData.images;

    const { formatLargeNumber } = useFormatter();

    return (
        <>
            <div className="modal-header">
                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">
                <CountriesAndBid state={state} setState={setState} items={items} setItems={setItems} />
                <br />
                <img src={map} className="geo-modal-cover" alt="" />
                <br />
            </div>
            <div className="modal-footer">
                {!result ? (
                    ""
                ) : (
                    <AudienceEstimate {...{ isEstimatedSizeLoading, isUnableToEstimate, result }} />
                )}
            </div>

            <Button
                variant="contained"
                color="primary"
                style={{ width: "100%" }}
                onClick={closeModal}
                name="Done"
                // size="sm"
            />
        </>
    );
};

const TargetingJoyride = ({ activeTab }) => {
    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: [
            {
                content: <h2 className="joyride-subtitle"> Adjust audience settings here.</h2>,
                locale: { skip: <strong>Skip</strong> },
                placement: "center",
                target: "body",
                title: "Targeting",
            },
            {
                content: (
                    <h2 className="joyride-subtitle">
                        Set web3 targeting parameters, such as token holders or blockchain users.
                    </h2>
                ),
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=targeting-tutorial-1]",
                title: "Web3 Targeting Parameters",
                // scrollToOffset: 260,
            },
            {
                content: (
                    <h2 className="joyride-subtitle">
                        Set web2 targeting parameters, such as countries or specific browsers.
                    </h2>
                ),
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=targeting-tutorial-2]",
                title: "Web2 Targeting Parameters",
                // scrollToOffset: 260,
            },
            {
                content: <h2 className="joyride-subtitle">Click here to proceed to the next steps.</h2>,
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=targeting-tutorial-3]",
                title: "Proceed to Next Steps",
                // scrollToOffset: 260,
            },
        ],
    });

    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;

        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "targetingTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (guideTutorials && guideTutorials.includes("targetingTutorial")) {
            if (activeTab == 1) {
                setTimeout(() => {
                    setSteps((prev) => ({ ...prev, run: true }));
                }, 500);
            }
        }
    }, [activeTab]);
    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon
            // disableScrolling={true}
            // disableScrollParentFix={true}
            scrollOffset={100}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    );
};
