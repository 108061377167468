import React, { useState, useEffect } from "react";
import { Field, Switch, Button, Carousel, AdsCard, UModal } from "../../../components";
import localData from "../../../localData";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext";
import { auth } from "../../../config/firebase";
import useFetch from "../../../hooks/useFetch";
import { Tooltip } from "react-tooltip";
import Joyride, { STATUS } from "react-joyride";
import ReactDOMServer from "react-dom/server";

export default function Summary({ setActiveTab, activeTab, campaignWizardStatus, setCampaignWizardStatus }) {
    const { budget, date, arrowLeft, arrowRight } = localData.svgs;
    const { image1, image2, image3, image4, preloaderBig, DATA64IMAGE } = localData.images;

    const {
        errorAlert,
        successAlert,
        getLoadCampaignData,
        requestedData,
        campaignState,
        creatives,
        campaignsState,
    } = useGlobalContext();
    const { balance, balancePredicted } = requestedData.userProfileData.currentOrganization;

    const [isLoading, setIsLoading] = useState(false);
    const { createNewCampaign, editCampaign } = useFetch();

    function filterEmptyProps(obj) {
        const filtered = {};
        for (const key in obj) {
            if (obj[key] !== "" && obj[key] !== null) {
                filtered[key] = obj[key];
            }
        }
        return filtered;
    }

    const handlecreateCampaign = async () => {
        setIsLoading(true);
        // const filteredCampaignInfo =  filterEmptyProps(campaignState.campaignInfo)
        // const filteredCampaignState = {...campaignState,campaignInfo:filteredCampaignInfo}

        const filteredCampaignState = { ...campaignState };
        // filteredCampaignState.campaignInfo = {...filteredCampaignState.campaignInfo}
        const isEndDateExist = filteredCampaignState.campaignInfo.endDate;
        if (!isEndDateExist) delete filteredCampaignState.campaignInfo.endDate;

        const campaignData = filterEmptyProps(campaignState.campaignInfo);

        const [day, month, year] = campaignData.startDate.split("/").map(Number);
        const date = new Date(year, month - 1, day); // Months are 0-based in JavaScript Date
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
        const dd = String(date.getDate()).padStart(2, "0");

        const formattedStartDate = `${yyyy}-${mm}-${dd} 00:00:00`;

        if (isEndDateExist) {
            const [day2, month2, year2] = campaignData.endDate
                ? campaignData.endDate.split("/").map(Number)
                : [0, 0, 2100];
            const date2 = new Date(year2, month2 - 1, day2); // Months are 0-based in JavaScript Date
            // Extract year, month, and day from the Date object
            const yyyy2 = date2.getFullYear();
            const mm2 = String(date2.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
            const dd2 = String(date2.getDate()).padStart(2, "0");

            var formattedendDate = `${yyyy2}-${mm2}-${dd2} 00:00:00`;
        } else {
            formattedendDate = null;
        }

        const isChainTargetingExist = filteredCampaignState.campaignInfo.chainTargeting.length;
        if (!isChainTargetingExist) filteredCampaignState.campaignInfo.chainTargeting = ["BSC"];

        const isCountiesTargetingExist = filteredCampaignState.campaignInfo.countriesTargeting.length;
        if (!isCountiesTargetingExist)
            // filtered countries, many countries removed
            filteredCampaignState.campaignInfo.countriesTargeting = [
                "ad",
                "ai",
                "al",
                "am",
                "an",
                "ao",
                "aq",
                "ar",
                "as",
                "at",
                "au",
                "aw",
                "ax",
                "az",
                "ba",
                "bb",
                "be",
                "bg",
                "bh",
                "bi",
                "bl",
                "bm",
                "bn",
                "bo",
                "bq",
                "br",
                "bs",
                "bt",
                "bv",
                "bw",
                "by",
                "ca",
                "cc",
                "cd",
                "cf",
                "cg",
                "ch",
                "ck",
                "cl",
                "cn",
                "co",
                "cr",
                "cu",
                "cv",
                "cw",
                "cx",
                "cz",
                "de",
                "dk",
                "dm",
                "do",
                "dz",
                "ee",
                "eg",
                "eh",
                "er",
                "es",
                "et",
                "fi",
                "fj",
                "fk",
                "fo",
                "fr",
                "ga",
                "gb",
                "gd",
                "ge",
                "gf",
                "gg",
                "gh",
                "gi",
                "gl",
                "gp",
                "gq",
                "gr",
                "gs",
                "gt",
                "gu",
                "gw",
                "gy",
                "hk",
                "hm",
                "hn",
                "hr",
                "ht",
                "hu",
                "id",
                "ie",
                "il",
                "im",
                "in",
                "io",
                "iq",
                "ir",
                "is",
                "it",
                "je",
                "jm",
                "jo",
                "jp",
                "kg",
                "kh",
                "ki",
                "km",
                "kn",
                "kp",
                "kr",
                "kw",
                "ky",
                "kz",
                "la",
                "lb",
                "lc",
                "li",
                "lk",
                "lr",
                "ls",
                "lt",
                "lu",
                "lv",
                "ly",
                "ma",
                "mc",
                "md",
                "me",
                "mf",
                "mg",
                "mh",
                "mk",
                "ml",
                "mm",
                "mn",
                "mo",
                "mp",
                "mq",
                "mr",
                "ms",
                "mt",
                "mu",
                "mv",
                "mw",
                "mx",
                "my",
                "mz",
                "na",
                "nc",
                "ne",
                "nf",
                "ng",
                "ni",
                "nl",
                "no",
                "np",
                "nr",
                "nu",
                "nz",
                "om",
                "pa",
                "pe",
                "pf",
                "pg",
                "ph",
                "pk",
                "pl",
                "pm",
                "pn",
                "pr",
                "ps",
                "pt",
                "pw",
                "py",
                "qa",
                "re",
                "ro",
                "rs",
                "ru",
                "rw",
                "sa",
                "sb",
                "sc",
                "sd",
                "se",
                "sg",
                "sh",
                "si",
                "sj",
                "sk",
                "sl",
                "sm",
                "sn",
                "so",
                "sr",
                "ss",
                "st",
                "sv",
                "sx",
                "sy",
                "sz",
                "tc",
                "td",
                "tf",
                "tg",
                "th",
                "tj",
                "tk",
                "tl",
                "tm",
                "tn",
                "to",
                "tr",
                "tt",
                "tv",
                "tw",
                "tz",
                "ua",
                "ug",
                "um",
                "us",
                "uy",
                "uz",
                "va",
                "vc",
                "ve",
                "vg",
                "vi",
                "vn",
                "vu",
                "wf",
                "ws",
                "ye",
                "yt",
                "za",
                "zm",
                "zw",
            ];

        // all countries
        // filteredCampaignState.campaignInfo.countriesTargeting = [
        //     "ad",
        //     "af",
        //     "ag",
        //     "ai",
        //     "al",
        //     "am",
        //     "an",
        //     "ao",
        //     "aq",
        //     "ar",
        //     "as",
        //     "at",
        //     "au",
        //     "aw",
        //     "ax",
        //     "az",
        //     "ba",
        //     "bb",
        //     "bd",
        //     "be",
        //     "bf",
        //     "bg",
        //     "bh",
        //     "bi",
        //     "bj",
        //     "bl",
        //     "bm",
        //     "bn",
        //     "bo",
        //     "bq",
        //     "br",
        //     "bs",
        //     "bt",
        //     "bv",
        //     "bw",
        //     "by",
        //     "bz",
        //     "ca",
        //     "cc",
        //     "cd",
        //     "cf",
        //     "cg",
        //     "ch",
        //     "ci",
        //     "ck",
        //     "cl",
        //     "cm",
        //     "cn",
        //     "co",
        //     "cr",
        //     "cu",
        //     "cv",
        //     "cw",
        //     "cx",
        //     "cy",
        //     "cz",
        //     "de",
        //     "dj",
        //     "dk",
        //     "dm",
        //     "do",
        //     "dz",
        //     "ec",
        //     "ee",
        //     "eg",
        //     "eh",
        //     "er",
        //     "es",
        //     "et",
        //     "fi",
        //     "fj",
        //     "fk",
        //     "fm",
        //     "fo",
        //     "fr",
        //     "ga",
        //     "gb",
        //     "gd",
        //     "ge",
        //     "gf",
        //     "gg",
        //     "gh",
        //     "gi",
        //     "gl",
        //     "gm",
        //     "gn",
        //     "gp",
        //     "gq",
        //     "gr",
        //     "gs",
        //     "gt",
        //     "gu",
        //     "gw",
        //     "gy",
        //     "hk",
        //     "hm",
        //     "hn",
        //     "hr",
        //     "ht",
        //     "hu",
        //     "id",
        //     "ie",
        //     "il",
        //     "im",
        //     "in",
        //     "io",
        //     "iq",
        //     "ir",
        //     "is",
        //     "it",
        //     "je",
        //     "jm",
        //     "jo",
        //     "jp",
        //     "ke",
        //     "kg",
        //     "kh",
        //     "ki",
        //     "km",
        //     "kn",
        //     "kp",
        //     "kr",
        //     "kw",
        //     "ky",
        //     "kz",
        //     "la",
        //     "lb",
        //     "lc",
        //     "li",
        //     "lk",
        //     "lr",
        //     "ls",
        //     "lt",
        //     "lu",
        //     "lv",
        //     "ly",
        //     "ma",
        //     "mc",
        //     "md",
        //     "me",
        //     "mf",
        //     "mg",
        //     "mh",
        //     "mk",
        //     "ml",
        //     "mm",
        //     "mn",
        //     "mo",
        //     "mp",
        //     "mq",
        //     "mr",
        //     "ms",
        //     "mt",
        //     "mu",
        //     "mv",
        //     "mw",
        //     "mx",
        //     "my",
        //     "mz",
        //     "na",
        //     "nc",
        //     "ne",
        //     "nf",
        //     "ng",
        //     "ni",
        //     "nl",
        //     "no",
        //     "np",
        //     "nr",
        //     "nu",
        //     "nz",
        //     "om",
        //     "pa",
        //     "pe",
        //     "pf",
        //     "pg",
        //     "ph",
        //     "pk",
        //     "pl",
        //     "pm",
        //     "pn",
        //     "pr",
        //     "ps",
        //     "pt",
        //     "pw",
        //     "py",
        //     "qa",
        //     "re",
        //     "ro",
        //     "rs",
        //     "ru",
        //     "rw",
        //     "sa",
        //     "sb",
        //     "sc",
        //     "sd",
        //     "se",
        //     "sg",
        //     "sh",
        //     "si",
        //     "sj",
        //     "sk",
        //     "sl",
        //     "sm",
        //     "sn",
        //     "so",
        //     "sr",
        //     "ss",
        //     "st",
        //     "sv",
        //     "sx",
        //     "sy",
        //     "sz",
        //     "tc",
        //     "td",
        //     "tf",
        //     "tg",
        //     "th",
        //     "tj",
        //     "tk",
        //     "tl",
        //     "tm",
        //     "tn",
        //     "to",
        //     "tr",
        //     "tt",
        //     "tv",
        //     "tw",
        //     "tz",
        //     "ua",
        //     "ug",
        //     "um",
        //     "us",
        //     "uy",
        //     "uz",
        //     "va",
        //     "vc",
        //     "ve",
        //     "vg",
        //     "vi",
        //     "vn",
        //     "vu",
        //     "wf",
        //     "ws",
        //     "ye",
        //     "yt",
        //     "za",
        //     "zm",
        //     "zw",
        // ];
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        try {
            filteredCampaignState.creatives = filteredCampaignState.creatives.map(
                ({ name, width, height, ...rest }) => rest
            );

            const creatives = JSON.parse(localStorage.getItem("creatives"));

            const data = await createNewCampaign(() => {}, filteredCampaignState, organizationID);
            campaignsState.resetCampaignsState();
            successAlert(data.res_msg);

            for (const banner of creatives) {
                const bannerData = data.creatives.find((item) => item.title === banner.title);
                console.log(bannerData);
                if (bannerData) {
                    banner.firebase_id = bannerData.id;
                }
            }

            // const token = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/token.php`, {
            //     method: "POST",
            //     headers: { "Content-Type": "application/json" },
            //     body: JSON.stringify({
            //         client_id: "blockchain-ads",
            //         client_secret: "blockchain-adsapi",
            //         grant_type: "client_credentials",
            //     }),
            // });

            // const access_token = await token.json();
            const response = await fetch(
                // `${process.env.REACT_APP_API_ENDPOINT}/advertiser/advertiser-getdetails.php?access_token=` +
                //     access_token.access_token,
                `${process.env.REACT_APP_API_ENDPOINT}/advertiser/advertiser-getdetails.php`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        firebase_id: requestedData.userProfileData.userId,
                    }),
                }
            );
            const returnJSONdata = await response.json();

            if (returnJSONdata !== null) {
                console.log(data.dailyLimit, "  data.dailyLimit");
                const campaignresponse = await fetch(
                    // `${process.env.REACT_APP_API_ENDPOINT}/advertiser/campaign-create.php?access_token=` +
                    //     access_token.access_token,
                    `${process.env.REACT_APP_API_ENDPOINT}/advertiser/campaign-create.php`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            campaignname: filteredCampaignState.campaignInfo.campaignName,
                            userType: "ADVERTISER",
                            clientid: returnJSONdata.clientid,
                            firebase_id: data.campaign_id,
                            user_id: returnJSONdata.user_id,
                            revenue_type: 1,
                            starttime: formattedStartDate,
                            endtime: formattedendDate,
                            currentstatus: data.currentStatus,
                            dailylimit: data.dailyLimit,
                        }),
                    }
                );
                const returnCampaigndata = await campaignresponse.json();

                for (const banner of creatives) {
                    const countriesTargeting = filteredCampaignState.campaignInfo.countriesTargeting;
                    const [width, height] = banner.size.split("x");
                    try {
                        const response = await fetch(
                            // `${process.env.REACT_APP_API_ENDPOINT}/advertiser/banner-create.php?access_token=` +
                            //     access_token.access_token,
                            `${process.env.REACT_APP_API_ENDPOINT}/advertiser/banner-create.php`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    filename: banner.name,
                                    tag: "",
                                    width: banner.width,
                                    height: banner.height,
                                    bannername: banner.title?.toString() || campaignData.campaignName,
                                    campaignid: returnCampaigndata.campaignid,
                                    ad_type: "web",
                                    url: campaignData.targetURL,
                                    user_id: returnJSONdata.user_id,
                                    firebase_id: banner.firebase_id,
                                    countriesTargeting,
                                    width,
                                    height,
                                    append: returnCampaigndata.append,
                                    prepend: returnCampaigndata.prepend,
                                }),
                            }
                        );

                        if (!response.ok) {
                            throw new Error(`Failed to create banner: ${response.statusText}`);
                        }
                        const result = await response.json();
                        //console.log('Banner created successfully:', result);
                        // successAlert("Campaign and Creative Created Successfully");
                        const campaignsDrawerClose = document.querySelector(".campaigns-drawer-close");
                        if (campaignsDrawerClose) campaignsDrawerClose.click();
                    } catch (error) {
                        console.error("Error creating banner:", error);
                    }
                }
            }

            const campaignsStates = JSON.parse(localStorage.getItem("campaignsStates"));
            getLoadCampaignData(
                requestedData.userProfileData.currentOrganization.id,
                1,
                campaignsStates?.pageSize || 10
            );

            const campaignsDrawerClose = document.querySelector(".campaigns-drawer-close");
            if (campaignsDrawerClose) campaignsDrawerClose.click();
        } catch (err) {
            let errorMessage = err?.response?.data?.res_msg;
            if (typeof errorMessage !== "string") errorMessage = err?.response?.data?.res_msg?.status[0];
            errorAlert(errorMessage || "Internal Server Error");
            console.error(err, "=createNewCampaign= request error");
        }
        setIsLoading(false);
    };

    const handleEditCampaign = async () => {
        setIsLoading(true);
        const filteredCampaignState = {
            uuid: campaignState.uuid,
            organizationID: requestedData.userProfileData.currentOrganization.id,
            creatorEmail: campaignState.creatorEmail,
            campaignId: campaignState.campaignId,
            newCampaignInfo: {
                name: campaignState.campaignInfo.campaignName,
                limit_daily_amount: campaignState.campaignInfo.budget,
                endDate: campaignState.campaignInfo.endDate,
                targetURL: campaignState.campaignInfo.targetURL,
                smartContractAddress: campaignState.campaignInfo.smartContractAddress,
                // maxCPC: campaignState.campaignInfo.maxCPC,
                category: campaignState.campaignInfo.category,
                web3Audience: campaignState.campaignInfo.web3Audience,
                chainTargeting: campaignState.campaignInfo.chainTargeting,
                campaignType: campaignState.campaignInfo.campaignType,
                walletTargeting: campaignState.campaignInfo.walletTargeting,
                deviceType: campaignState.campaignInfo.deviceType,
                operativeSystem: campaignState.campaignInfo.operativeSystem,
                browser: campaignState.campaignInfo.browser,
                countriesTargeting: campaignState.campaignInfo.countriesTargeting,
            },
        };

        const isEndDateExist = filteredCampaignState.newCampaignInfo.endDate;
        if (!isEndDateExist) delete filteredCampaignState.newCampaignInfo.endDate;

        const isNewCreativeExist = campaignState.creatives.find((creative) => !creative.id);

        if (isNewCreativeExist) {
            filteredCampaignState.creatives = campaignState.creatives.map((creative) => {
                return {
                    title: creative.title,
                    image: creative.image,
                    apiImage: creative.apiImage,
                    size: creative.size,
                    id: creative.id?.toString() || "new",
                };
            });
        }
        // console.log(filteredCampaignState)

        try {
            const data = await editCampaign(() => {}, filteredCampaignState);
            successAlert(data.res_msg);

            const campaignsStates = JSON.parse(localStorage.getItem("campaignsStates"));
            getLoadCampaignData(
                requestedData.userProfileData.currentOrganization.id,
                1,
                campaignsStates?.pageSize || 10
            );
            campaignsState.resetCampaignsState();
            const campaignsDrawerClose = document.querySelector(".campaigns-drawer-close");
            if (campaignsDrawerClose) campaignsDrawerClose.click();
        } catch (err) {
            let errorMessage = err?.response?.data?.res_msg;
            if (typeof errorMessage !== "string") errorMessage = err?.response?.data?.res_msg?.message;
            errorAlert(errorMessage || "Internal Server Error");
            console.error(err, "=editUser= request error");
        }
        setIsLoading(false);
    };

    const getSummaryItems = (property) => {
        let value = "";

        if (!campaignState.campaignInfo[property].length) {
            value = "-";
            return value;
        }
        value = campaignState.campaignInfo[property].map((item, index) => (
            <span key={index}>
                {item}
                {index !== campaignState.campaignInfo[property].length - 1 && ", "}
            </span>
        ));
        let valueWithTooltip = (
            <span
                data-tooltip-id="tooltip-summary-block"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(value)}
            >
                {value}
            </span>
        );
        return valueWithTooltip;
        // return !campaignState.campaignInfo[property].length ? (
        //     "-"
        // ) : (
        //     <div
        //         data-tooltip-id="tooltip-summary-block"
        //         data-tooltip-html={ReactDOMServer.renderToStaticMarkup(item)}
        //     >
        //         {campaignState.campaignInfo[property].map((item, index) => (
        //             <span key={index}>
        //                 {item}
        //                 {index !== campaignState.campaignInfo[property].length - 1 && ", "}
        //             </span>
        //         ))}
        //     </div>
        // );
    };

    // const handleViewCampaignInfo = async () => {
    //     const infoRaw = {
    //         uuid: campaignState.uuid,
    //         campaignId: campaignInfo.id,
    //     };
    //     try {
    //         const data = await viewCampaignInfo(() => {}, infoRaw);
    //         console.log(data, " viewCampaignInfo request");
    //         successAlert(data.res_msg);
    //     } catch (err) {
    //         errorAlert(err.response?.data?.res_msg || "error");
    //         console.log(err, "error triggered in Summary.jsx");
    //     }
    // };

    return (
        <div className="summary">
            <SummaryJoyride activeTab={activeTab} />
            <div className="wrapper  tab-content-creatives">
                <h2 className="summary-title display-2">Campaign Summary</h2>
                <div data-guide="summary-tutorial-1">
                    <div className="summary-block wizard-block">
                        <h4 className="summary-block-title">Campaign Information</h4>
                        <div className="summary-block-content">
                            <div className="col">
                                <div className="wrapper">
                                    <div className="wrapper-title">Campaign Name</div>
                                    <div className="wrapper-value">
                                        {campaignState.campaignInfo.campaignName || "-"}
                                    </div>
                                </div>
                                <div className="wrapper">
                                    <div className="wrapper-title">Target URL</div>
                                    <div className="wrapper-value">
                                        {campaignState.campaignInfo.targetURL || "-"}
                                    </div>
                                </div>
                                <div className="wrapper">
                                    <div className="wrapper-title">Smart Contract</div>
                                    <div className="wrapper-value">
                                        {campaignState.campaignInfo.smartContractAddress || "-"}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="wrapper">
                                    <div className="wrapper-title">Budget</div>
                                    <div className="wrapper-value">
                                        {campaignState.campaignInfo.budget || "-"}
                                    </div>
                                </div>

                                <div className="wrapper">
                                    <div className="wrapper-title">Max CPC</div>
                                    <div className="wrapper-value">
                                        {campaignState.campaignInfo.maxCPC || "-"}
                                    </div>
                                </div>
                                <div className="wrapper">
                                    <div className="wrapper-title">Start Date</div>
                                    <div className="wrapper-value">
                                        {campaignState.campaignInfo.startDate || "-"}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="wrapper">
                                    <div className="wrapper-title">End Date</div>
                                    <div className="wrapper-value">
                                        {campaignState.campaignInfo.endDate || "-"}
                                    </div>
                                </div>
                                <div className="wrapper">
                                    <div className="wrapper-title">Campaign Type</div>
                                    <div className="wrapper-value">{getSummaryItems("campaignType")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />

                    <div className="summary-block wizard-block">
                        <h4 className="summary-block-title">Targeting</h4>
                        <div className="summary-block-content">
                            <div className="col">
                                <div className="wrapper">
                                    <div className="wrapper-title">Category</div>
                                    <div className="wrapper-value line-ellipsis">{getSummaryItems("category")}</div>
                                </div>
                                <div className="wrapper">
                                    <div className="wrapper-title">Web3 Audiences</div>
                                    <div className="wrapper-value line-ellipsis">{getSummaryItems("web3Audience")}</div>
                                </div>
                                <div className="wrapper">
                                    <div className="wrapper-title">Operative System</div>
                                    <div className="wrapper-value line-ellipsis">{getSummaryItems("operativeSystem")}</div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="wrapper">
                                    <div className="wrapper-title">Geo</div>
                                    <div className="wrapper-value line-ellipsis">
                                        {getSummaryItems("countriesTargeting")}
                                    </div>
                                </div>
                                <div className="wrapper">
                                    <div className="wrapper-title">Wallet Targeting</div>
                                    <div className="wrapper-value line-ellipsis">{getSummaryItems("walletTargeting")}</div>
                                </div>
                                <div className="wrapper">
                                    <div className="wrapper-title">Chain Targeting</div>
                                    <div className="wrapper-value line-ellipsis">{getSummaryItems("chainTargeting")}</div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="wrapper">
                                    <div className="wrapper-title">Browser</div>
                                    <div className="wrapper-value line-ellipsis">{getSummaryItems("browser")}</div>
                                </div>
                                <div className="wrapper">
                                    <div className="wrapper-title">Device Type</div>
                                    <div className="wrapper-value line-ellipsis">{getSummaryItems("deviceType")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Tooltip id="tooltip-summary-block" className="custom-tooltip" />
                <br />
                <br />

                <div className="summary-block wizard-block">
                    <h4 className="carousel-title">Ads</h4>
                    {!creatives.length ? (
                        "-"
                    ) : (
                        <>
                            <Carousel
                                items={creatives}
                                Card={AdsCard}
                                cardOptions={{ readOnly: true }}
                                options={{
                                    spaceBetween: 10,
                                    autoplay: false,
                                    loop: false,
                                    breakpoints: {
                                        480: {
                                            slidesPerView: 2,
                                        },
                                        992: {
                                            slidesPerView: 3,
                                        },
                                        1360: {
                                            slidesPerView: 4,
                                        },
                                    },
                                }}
                            />
                        </>
                    )}
                </div>
                <br />
                <br />

                <div className="row row-bottom btn-group">
                    <Button
                        size="sm"
                        startIcon={arrowLeft}
                        name="Back"
                        variant="text"
                        color="dark"
                        onClick={() => setActiveTab((prevNumber) => 2)}
                    />
                    <div
                        className={`${
                            campaignWizardStatus === "create" && balancePredicted.toFixed(0) <= 0
                                ? "not-editable"
                                : ""
                        }`}
                        data-tooltip-id="tooltip-submit-btn"
                        data-tooltip-content={
                            campaignWizardStatus === "create" && balancePredicted.toFixed(0) <= 0
                                ? "“Top up your account balance before launching a campaign”."
                                : ""
                        }
                    >
                        <Button
                            className="submit-btn"
                            size="sm"
                            name={campaignWizardStatus === "create" ? "Save & Continue" : "Edit & Continue"}
                            variant="contained"
                            color={campaignWizardStatus === "create" ? "success" : "warning"}
                            onClick={
                                campaignWizardStatus === "create" ? handlecreateCampaign : handleEditCampaign
                            }
                            icon={
                                isLoading ? (
                                    <span className="endIcon">
                                        <img src={preloaderBig} />
                                    </span>
                                ) : null
                            }
                            disabled={balancePredicted.toFixed(0) <= 0 || isLoading}
                            dataGuide="summary-tutorial-2"
                        />
                        <Tooltip id="tooltip-submit-btn" className="custom-tooltip" />
                    </div>
                </div>
                <br />
                <br />
                <br />
            </div>
        </div>
    );
}

const SummaryJoyride = ({ activeTab }) => {
    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: [
            {
                content: (
                    <h2 className="joyride-subtitle">Campaign Overview: Summary of essential information.</h2>
                ),
                locale: { skip: <strong>Skip</strong> },
                placement: "center",
                target: "body",
                title: "Summary",
            },
            {
                content: (
                    <h2 className="joyride-subtitle">
                        Review all campaign details. Go back to previous steps if needed.
                    </h2>
                ),
                locale: { skip: <strong>Skip</strong> },
                placement: "left",
                target: "[data-guide=summary-tutorial-1]",
                title: "Summary View",
                // scrollToOffset: 260,
            },
            {
                content: (
                    <h2 className="joyride-subtitle">
                        Click here to launch the campaign. It will start after publishers review (usually
                        takes a few hours).
                    </h2>
                ),
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=summary-tutorial-2]",
                title: "Launching Your Campaign",
                // scrollToOffset: 260,
            },
        ],
    });

    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;

        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "summaryTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (guideTutorials && guideTutorials.includes("summaryTutorial")) {
            if (activeTab == 3) {
                setTimeout(() => {
                    setSteps((prev) => ({ ...prev, run: true }));
                }, 500);
            }
        }
    }, [activeTab]);

    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon
            // disableScrolling={true}
            // disableScrollParentFix={true}
            scrollOffset={100}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    );
};
