import React, { useState, useEffect } from "react";
import localData from "../../localData";
import { useGlobalContext } from "../../context";
import { Button, CopyTextButton, Search, TableSkeleton, Field } from "..";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../hooks/useFormatter";
import useSort from "../../hooks/useSort";
import useAccountsSort from "./useAccountsSort";
import { getAllByAltText } from "@testing-library/react";
const { close, arrowDown, arrowUp, eyeSolid, ServerDown, ServerLeave } = localData.svgs;

export default function Accounts({ setIsOpen }) {
    const closeModal = () => {
        setIsOpen(false);
    };
    const { textPreloader } = localData.images;

    const {
        requestedData: {
            userProfileData: { allOrganization },
        },
        requestedData,
        setRequestedData,
        authUser,
        setAuthUser,
        errorAlert,
    } = useGlobalContext();

    const [accountOrganization, setAccountOrganization] = useState(null);
    const [filteredOrganizations, setFilteredOrganizations] = useState([]);

    useEffect(() => {
        const organization = allOrganization.find(
            (item) => item.adminID === requestedData.userProfileData.userId
        );
        setAccountOrganization(organization);
        setFilteredOrganizations(allOrganization);
    }, [requestedData]);

    const { handleOnClick, getSortIcon } = useAccountsSort({ requestedData, setRequestedData });

    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isOrganizationsLoading, setIsOrganizationsLoading] = useState(false);
    const [isQueryExist, setIsQueryExist] = useState(false);

    const handleFind = (query) => {
        let queryItems = allOrganization.filter((item) => item.id.startsWith(query));

        if (!queryItems.length) {
            queryItems = allOrganization.filter((item) => item.adminName.startsWith(query));
        }

        if (!queryItems.length) {
            queryItems = allOrganization.filter((item) => item.adminEmail.startsWith(query));
        }

        if (!queryItems.length) return errorAlert("Organization not exist.");
        setIsQueryExist(true);
        setIsOrganizationsLoading(true);

        setFilteredOrganizations(queryItems);

        setTimeout(() => setIsOrganizationsLoading(false), 1000);
    };

    const handleFilter = (id) => {
        const tempFilteredOrganizations = filteredOrganizations.filter((item) => item.id !== id);
        if (!tempFilteredOrganizations.length) {
            setIsOrganizationsLoading(true);
            setFilteredOrganizations(allOrganization);
            setTimeout(() => setIsOrganizationsLoading(false), 1000);
            setIsQueryExist(false);
            return;
        }

        setFilteredOrganizations(tempFilteredOrganizations);
    };

    useEffect(() => {
        const cleanOldBcaNames = () => {
            let storedBcaNames = JSON.parse(localStorage.getItem("bcaNames"));
            if (!storedBcaNames || !storedBcaNames.length) return;
            storedBcaNames = storedBcaNames.filter((item) => item.created_at >= Date.now() - 1000 * 60 * 30);

            localStorage.setItem("bcaNames", JSON.stringify(storedBcaNames));
        };
        cleanOldBcaNames();
    }, []);

    return (
        <>
            <div className="modal-header">
                <Button
                    className="btn-close"
                    variant="circle"
                    color="light"
                    // size="sm"
                    icon={close}
                    onClick={closeModal}
                />
                <div id="app">
                    <div id="wrapper">
                        <h1 className="glitch-2" data-text="Database">
                            Database
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <ul className="date">
                        <li>
                            <b>Name:</b>
                            <span>{authUser?.currentUser?.displayName}</span>
                        </li>
                        <li>
                            <b>Email:</b>
                            <span>{authUser?.currentUser?.email}</span>
                        </li>
                        <li>
                            <b>Organization name:</b>
                            <span>{accountOrganization?.name}</span>
                        </li>
                        <li>
                            <b>Account created:</b>
                            <span>{authUser?.currentUser?.metadata?.creationTime}</span>
                        </li>
                    </ul>
                    {<ServerDown className="accounts-cover" />}
                </div>
            </div>
            <div className="modal-body" style={{ padding: "0px 1rem 20px" }}>
                <div className="search-bar-wrapper">
                    <h5 className="modal-title display-2">ORGANIZATIONS</h5>
                    <Search
                        {...{
                            variant: "contained",
                            className: `accounts-search`,
                            isOpen: isSearchOpen,
                            setIsOpen: setIsSearchOpen,
                            size: "sm",
                            color: "purple",
                            disabled: isOrganizationsLoading,
                            callback: handleFind,
                            placeholder: "Filter Address / ID, BCA Name",
                        }}
                    />
                    {isQueryExist &&
                        !isOrganizationsLoading &&
                        filteredOrganizations.map((item, index) => {
                            return (
                                <div className="query-badge" key={index}>
                                    <div className="query-badge-text">{item.adminName}</div>
                                    <Button
                                        variant="circle"
                                        icon={close}
                                        size="sm"
                                        color="secondary"
                                        onClick={() => handleFilter(item.id)}
                                    />
                                </div>
                            );
                        })}
                </div>
                <div className="scroll">
                    {isOrganizationsLoading ? (
                        <TableSkeleton message="Loading..." icon={textPreloader} />
                    ) : (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="table-th-details text-center">
                                        {/* <Popup
                                        position="right"
                                        toggler="?"
                                        content="See preview of the campaign details."
                                    /> */}
                                        <div
                                            className="details-icon"
                                            style={{ margin: "0 auto" }}
                                            data-tooltip-id="tooltip-organization-details"
                                            data-tooltip-content={`See the members.`}
                                        >
                                            {eyeSolid}
                                        </div>
                                    </th>
                                    <th style={{ width: 0, paddingLeft: 2, paddingRight: 2 }}>#</th>
                                    <th style={{ width: 0 }}>Admin Name</th>
                                    <th style={{ width: 0 }}>BCA Name</th>
                                    <th className="">Admin Email</th>
                                    <th>
                                        <span
                                            className="with-sort"
                                            onClick={() => {
                                                setIsQueryExist(false);
                                                handleOnClick("activeStatus", null, () => {});
                                            }}
                                        >
                                            Status{getSortIcon("activeStatus")}
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className="with-sort"
                                            onClick={() => {
                                                setIsQueryExist(false);
                                                handleOnClick("currentBalance", null, () => {});
                                            }}
                                        >
                                            Current Balance {getSortIcon("currentBalance")}
                                        </span>
                                    </th>
                                    <th className="">Admin ID</th>
                                    <th className="">ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!filteredOrganizations.length ? (
                                    <tr>
                                        <td>
                                            <div className="no-data">No Data To Show!</div>
                                        </td>
                                    </tr>
                                ) : (
                                    filteredOrganizations.map((item, index) => {
                                        const color =
                                            item.adminID === "3FoFz6dNudSQEJefCOEcortk9mN2"
                                                ? "text-purple"
                                                : item.adminID === requestedData.userProfileData.userId
                                                ? "text-success"
                                                : "";
                                        return (
                                            <AccountsRow
                                                key={index}
                                                {...{ ...item, item, index }}
                                                color={color}
                                            />
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    )}
                    <Tooltip id="tooltip-organization-details" className="custom-tooltip" />
                </div>
                <br />
                <br />

                {/* <h6 style={{ marginLeft: "20px" }}>
                    <strong>Organizations amount: </strong>
                    {allOrganization.length}
                </h6> */}
            </div>
            <div className="modal-footer">
                <Button
                    variant="contained"
                    color="light"
                    style={{ marginRight: "10px", minWidth: "150px" }}
                    onClick={closeModal}
                    className="accounts-close-btn"
                >
                    Leave
                    <ServerLeave />
                </Button>
            </div>
            <br />
            <br />
        </>
    );
}

const AccountsRow = ({ item, color, index }) => {
    const { addCommas, addCommasKeepMinus } = useFormatter();
    const [activeDetails, setActiveDetails] = useState({});
    const { updateAccountBCANameRequest } = useGlobalContext();

    const [bcaNameIsLoading, setBcaNameIsLoading] = useState(false);
    const [isBcaNameStored, setIsBcaNameStored] = useState(false);

    useEffect(() => {
        const storedBcaNames = JSON.parse(localStorage.getItem("bcaNames"));
        if (!storedBcaNames || !storedBcaNames.length) return;
        const isStored = storedBcaNames.find((bcaItem) => bcaItem.name == item.bcaName);
        if (isStored) setIsBcaNameStored(true);
    }, []);

    const handleOnBlur = (e) => {
        updateAccountBCANameRequest({
            bcaName: e.target.value,
            organizationID: item.id,
            setBcaNameIsLoading,
        });
        setIsBcaNameStored(true);

        const updateAccountInStorage = () => {
            const storedBcaNames = JSON.parse(localStorage.getItem("bcaNames")) || [];
            storedBcaNames.push({ name: e.target.name, created_at: Date.now() });

            localStorage.setItem("bcaNames", JSON.stringify(storedBcaNames));
        };
        updateAccountInStorage();
    };

    return (
        <>
            <tr
                className={`${
                    item.currentBalance == 0
                        ? ""
                        : item.currentBalance > 0
                        ? "bg-success-100"
                        : item.currentBalance < 0
                        ? "bg-danger-100"
                        : ""
                }`}
            >
                <td className="table-td-hush">
                    <Button
                        variant="circle"
                        size="sm"
                        color="secondary"
                        icon={activeDetails.id === item.id ? arrowUp : arrowDown}
                        onClick={(e) => {
                            e.stopPropagation();
                            setActiveDetails(activeDetails.id === item.id ? {} : { id: item.id });
                        }}
                    />{" "}
                </td>
                <td style={{ width: 0, paddingLeft: 2, paddingRight: 2 }}>{index + 1}.</td>
                <td title={item.name} className={`${color} table-td-name`}>
                    <div className="ellipsis"> {item.name}</div>
                    <CopyTextButton text={item.name} />
                </td>
                <td
                    title={item.bcaName}
                    className={`table-td-bca-name ${color} ${isBcaNameStored ? "disabled" : ""}`}
                    style={{ width: 0 }}
                >
                    {/* <input type="text" value={bcaName} onChange={handleOnChange} onBlur={handleOnBlur} /> */}
                    <Field
                        name={item.bcaName}
                        type="text"
                        labelInfo="change name here"
                        value={item.bcaName}
                        color="secondary"
                        placeholder="e.g. johnDoeCamp"
                        required={true}
                        size="sm"
                        // callback={handleOnChange}
                        onBlur={(e) => e.target.value !== item.bcaName && handleOnBlur(e)}
                        disabled={bcaNameIsLoading}
                    />
                </td>
                <td title={item.adminEmail} className={`${color} table-td-admin-email`}>
                    <div className="ellipsis">{item.adminEmail}</div>
                    <CopyTextButton text={item.adminEmail} />
                </td>
                <td className={``}>
                    <span className={` text-${item.activeStatus ? "success" : "secondary-500"}`}>
                        {item.activeStatus ? "Active" : "Inactive"}
                    </span>
                </td>
                <td
                    // className={`${color} text-${
                    //     item.currentBalance == 0
                    //         ? "secondary"
                    //         : item.currentBalance > 0
                    //         ? "success"
                    //         : "danger"
                    // }`}
                    className={`${color}`}
                >
                    ${addCommasKeepMinus(item.currentBalance) || 0}
                </td>
                <td className={`${color}`}>{item.adminID}</td>
                <td className={`${color}`} style={{ width: 0 }}>
                    {item.id} <CopyTextButton text={item.id} />
                </td>
            </tr>
            {activeDetails.id === item.id && (
                <>
                    {item.members.map((subItem, subIndex) => {
                        return (
                            <tr key={subIndex} className="details-tr">
                                <td colSpan={9} className="">
                                    {/* <h5 className="details-title" >Organization Members</h5> */}
                                    {subItem}
                                </td>
                            </tr>
                        );
                    })}
                </>
            )}
        </>
    );
};
