import React, { useEffect, useState } from "react";
import { Navbar, CampaignsWizard, Tabs, Modal, Button } from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import CampaignsGraph from "./campaigns-graph/CampaignsGraph.jsx";
import CampaignsTable from "./campaigns-table/CampaignsTable.jsx";
// import BlockchainsTable from "./blockchains-table/BlockchainsTable.jsx";
import Blockchains from "./blockchains/Blockchains.jsx";
import CampaignObjectiveModal from "./campaign-objective-modal/CampaignObjectiveModal.jsx";
import CampaignBuildOptionModal from "./campaign-build-option-modal/CampaignBuildOptionModal.jsx";
import Joyride, { STATUS } from "react-joyride";

export default function Campaigns() {
    useEffect(() => {
        document.title = "Blockchain-Ads Platform";

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content = "Empower change. Join our campaign today!";
    }, []);

    const { fade } = useGlobalContext().animations;
    const { requestedData, isLoading, getLoadBlockchainData, blockchainsState, setIsLoading } =
        useGlobalContext();
    const { blockchainTabs, setActiveNetwork } = blockchainsState;

    const { plus, arrowRightExtended, checkbox } = localData.svgs;

    const [showCampaignObjectiveModal, setShowCampaignObjectiveModal] = useState("none");
    const [showCampaignBuildOptionModal, setShowCampaignBuildOptionModal] = useState("none");

    return (
        <motion.main {...fade} className="main campaigns-page">
            <CampaignsJoyride />
            <Navbar title="campaigns" />

            <div className="row">
                <div className="col">
                    <h2 className="campaign-title display-2">all campaigns</h2>
                    <h4 className="description">Monitor metrics, check reports and review performance.</h4>
                </div>

                <Modal
                    restProps={{ setShowCampaignBuildOptionModal }}
                    Toggler={() => (
                        <Button
                            name="Create Campaign"
                            variant="contained"
                            startIcon={plus}
                            color="success"
                            id="login-tutorial-3"
                            onClick={() => setShowCampaignObjectiveModal("block")}
                        />
                    )}
                    Child={CampaignObjectiveModal}
                    display={showCampaignObjectiveModal}
                    setDisplay={setShowCampaignObjectiveModal}
                    className="modal-dialog-centered campaign-objective-modal"
                />
                <Modal
                    restProps={{ setShowCampaignObjectiveModal }}
                    Toggler={() => {}}
                    Child={CampaignBuildOptionModal}
                    display={showCampaignBuildOptionModal}
                    setDisplay={setShowCampaignBuildOptionModal}
                    className="modal-dialog-centered campaign-build-option-modal"
                />
            </div>
            <CampaignsWizard />

            <CampaignsGraph />
            <br />
            <br />
            <br />
            <br />
            <br />

            <CampaignsTable />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Blockchains />
        </motion.main>
    );
}

const CampaignsJoyride = () => {
    const defaultSteps = [
        {
            content: (
                <h2 className="joyride-subtitle">
                    {" "}
                    Welcome to Blockchain-Ads! Let's explore the main features of the platform.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "center",
            target: "body",
            title: "Welcome to Blockchain-Ads",
            // spotlightClicks: false, // Disable spotlight clicks
            // spotlightPadding: 0, // Remove spotlight padding
            // scrollToOffset: 260,
        },

        {
            content: (
                <h2 className="joyride-subtitle">Launch your first ad campaign here.[Launch Campaign]</h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#login-tutorial-3",
            title: "Launch Your First Ad Campaign",
            // scrollToOffset: 260,
        },

        {
            content: (
                <h2 className="joyride-subtitle">
                    Set up the pixel on the Tracking page and start measuring conversions.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#login-tutorial-6",
            title: "Setting Up Pixel Tracking",
            // scrollToOffset: 260,
            isHidden: window.innerWidth < 576,
        },

        {
            content: (
                <h2 className="joyride-subtitle">
                    To switch between your account and the accounts you are a member of, just use the dropdown
                    menu.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#login-tutorial-8",
            title: "Switch Accounts",

            isHidden: window.innerWidth < 576,
        },
    ];

    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: defaultSteps,
    });

    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;
        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "loginTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (!guideTutorials || !guideTutorials.includes("loginTutorial")) return;
        const filteredSteps = defaultSteps.filter((step) => !step.isHidden);
        setSteps((prev) => ({
            ...prev,
            run: true,
            steps: filteredSteps,
        }));
    }, []);

    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon
            // disableScrolling={true}
            // disableScrollParentFix={true}
            scrollOffset={200}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
                buttonNext: {},

                buttonBack: {},
                buttonClose: {},
            }}
        />
    );
};
